import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ModerateIcon = (props: SvgIconProps) => (
  <SvgIcon width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
    <path
      d="M20.0812 10.5983C20.0812 5.11816 15.631 0.687988 10.1262 0.687988C4.62131 0.687988 0.171143 5.11816 0.171143 10.5983C0.171143 16.0783 4.64703 20.5085 10.1262 20.5085C15.6053 20.5085 20.0812 16.0527 20.0812 10.5983Z"
      fill="#FFB600"
    />
    <path
      d="M6.80786 6.03962C6.80786 6.78225 7.39955 7.37123 8.14554 7.37123C8.89152 7.37123 9.48314 6.75664 9.48314 6.03962C9.48314 5.3226 8.89152 4.70801 8.14554 4.70801C7.39955 4.70801 6.80786 5.29699 6.80786 6.03962Z"
      fill="white"
    />
    <path
      d="M9.89258 6.03962C9.89258 6.78225 10.4842 7.37123 11.2302 7.37123C11.9762 7.37123 12.5679 6.75664 12.5679 6.03962C12.5679 5.3226 11.9762 4.70801 11.2302 4.70801C10.4842 4.70801 9.89258 5.29699 9.89258 6.03962Z"
      fill="white"
    />
    <path
      d="M6.47253 7.85791C6.13812 7.85791 5.88086 8.11399 5.88086 8.4469V11.8271C5.88086 12.16 6.13812 12.4161 6.47253 12.4161H6.65254V16.5902H9.81656V12.4161H10.0738C10.4082 12.4161 10.6654 12.16 10.6654 11.8271V8.4469C10.6654 8.11399 10.4082 7.85791 10.0738 7.85791H6.4982H6.47253Z"
      fill="white"
    />
    <path
      d="M11.3338 7.85791C11.411 8.03717 11.4624 8.24203 11.4624 8.4469V11.8271C11.4624 12.4161 11.1023 12.9027 10.6135 13.1331V16.5902H12.9286V12.4161H13.1859C13.5203 12.4161 13.7776 12.16 13.7776 11.8271V8.4469C13.7776 8.11399 13.5203 7.85791 13.1859 7.85791H11.3338Z"
      fill="white"
    />
  </SvgIcon>
);
