import React from "react";
import { Box } from "@mui/material";
import { Card } from "components/atoms/Card/Card";
import { PeriodSelect } from "components/molecules/PeriodSelect/PeriodSelect";
import { IconBadge } from "components/atoms/IconBadge/IconBadge";
import { ErrorIcon } from "assets/icons/ErrorIcon";
import { CardRecord } from "components/molecules/CardRecord/CardRecord";
import { PeriodEnum } from "enums/period.enum";

export const ErrorsCard = () => (
  <Card sx={{ background: "#ddd" }}>
    <Box display="flex" justifyContent="space-between">
      <IconBadge Icon={ErrorIcon} stroke="common.black" backgroundColor="#00000029" />
      <PeriodSelect value={PeriodEnum.WEEK} onChange={() => {}} />
    </Box>

    <Box display="flex">
      <CardRecord heading="Current Errors " content="0" error />
      <CardRecord heading="Error Log" content="30" />
    </Box>
  </Card>
);
