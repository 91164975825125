export enum ColorsEnum {
  PRIMARY = "#5570F1",
  SECONDARY = "#FF7E7E",
  NAV = "#28285B",
  NAV_ACTIVE = "#D45E90",
  SUCCESS = "#519C66",
  ERROR = "#D32F2F",
  ERROR_DARK = "#CC5F5F",
  HEADING = "#45464E",
  TEXT_SECONDARY = "#8B8D97",
  WARNING_FADED = "#FFCC9129",
  LINK = "#5969FA",
}
