import { createTheme, responsiveFontSizes } from "@mui/material";
import { ColorsEnum } from "enums/colors.enum";

const themeOptions = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
  },
  palette: {
    mode: "light",
    primary: {
      main: ColorsEnum.PRIMARY,
    },
    secondary: {
      main: ColorsEnum.SECONDARY,
    },
    success: {
      main: ColorsEnum.SUCCESS,
    },
    error: {
      main: ColorsEnum.ERROR,
    },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: ColorsEnum.NAV,
        },
      },
    },
  },
});

export const theme = responsiveFontSizes(themeOptions);
