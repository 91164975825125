import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  error: {
    "& fieldset, & label, & .MuiFormHelperText-root": {
      color: ColorsEnum.ERROR,
      borderColor: ColorsEnum.ERROR,
    },
  },
};
export default styles;
