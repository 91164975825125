import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  cell: {
    whiteSpace: "nowrap",
  },
  content: {
    position: "relative",
  },
  hidden: {
    visibility: "hidden",
  },
  skeleton: {
    width: "100%",
    height: 22,
    position: "absolute",
    top: 0,
    left: 0,
    transform: "unset",
  },
};
export default styles;
