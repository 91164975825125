import React from "react";
import { Paper, useMediaQuery, Box, useTheme, Typography } from "@mui/material";
import styles from "./styles";
import { GesamtTableItem } from "components/molecules/GesamtTableItem/GesamtTableItem";
import { GesamtTableTrend } from "components/molecules/GesamtTableTrend/GesamtTableTrend";

export const GesamtTable = () => {
  const theme = useTheme();
  const isShown = useMediaQuery(theme.breakpoints.up("md"));
  const isYearShown = useMediaQuery(theme.breakpoints.up("lg"));
  const isTrendShown = useMediaQuery(theme.breakpoints.up("xl"));
  return isShown ? (
    <Paper sx={styles.container} square>
      <Box sx={styles.grid}>
        <Box sx={styles.headerContainer}>
          <Typography sx={styles.header}>Gesamt</Typography>
        </Box>
        <GesamtTableItem header="Live" content={148} category="Züge in Betrieb" />
        <GesamtTableItem header="Live" content={117065} category="Personenanzahl" />
        <GesamtTableItem header="Tag" content={1323099} />
        <GesamtTableItem header="Woche" content={8301999} />
        <GesamtTableItem header="Monat" content={85858995} />
        {isYearShown && <GesamtTableItem header="2023" content={270058090} />}
        {isTrendShown && <GesamtTableTrend />}
      </Box>
    </Paper>
  ) : null;
};
