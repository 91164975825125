import React from "react";
import { Box } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

interface Props {
  color: string;
  label: string;
}

export const LegendItem = ({ color, label }: Props) => (
  <Box color="#A6A8B1" fontSize={12}>
    <FiberManualRecordIcon
      sx={{
        width: (theme) => theme.spacing(1.5),
        height: (theme) => theme.spacing(1.5),
        mr: 1,
        fill: color,
      }}
    />
    {label}
  </Box>
);
