import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    width: "100%",
    mb: 3,
    fontWeight: 500,
    fontSize: 20,
    color: ColorsEnum.HEADING,
  },
  form: {
    width: "100%",
    maxWidth: 1200,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  find: {
    width: "100%",
    maxWidth: 1200,
    mb: 4,
    px: 3,
    gridArea: "1 / 1 / 2 / 3",
    display: "flex",
    alignItems: "center",
    color: "#8B8D97",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
  },
  search: {
    maxWidth: 180,
    "& .MuiInputBase-root": {
      pl: 1,
    },
    "& input": {
      py: 1,
      fontSize: 12,
      lineHeight: "14px",
    },
  },
  column: {
    width: "100%",
    px: 3,
    display: "flex",
    flexDirection: "column",
  },
  field: {
    py: 2,
  },
  label: {
    mb: 1,
    color: "#53545C",
    fontSize: "1rem",
  },
  input: {
    width: "100%",
    borderRadius: 2,
    fontSize: 16,
    lineHeight: "19px",
    "& .MuiInputBase-root": {
      backgroundColor: "rgba(239, 241, 249, 0.6)",
    },
    "& input": {
      py: 1.5,
      px: 2,
    },
    "& fieldset": {
      border: "none",
    },
  },
  select: {
    width: "100%",
    height: 47,
    backgroundColor: "rgba(239, 241, 249, 0.6)",
    "& fieldset": {
      border: "none",
    },
  },
  pickers: {
    display: "flex",
    gap: 1,
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: 4,
    color: ColorsEnum.NAV,
  },
  button: {
    color: "white",
    backgroundColor: ColorsEnum.NAV,
    "&:hover": {
      backgroundColor: `${ColorsEnum.NAV}DD`,
    },
  },
  error: {
    color: ColorsEnum.ERROR,
  },
  dateError: {
    "& fieldset, & label, & .MuiFormHelperText-root": {
      color: ColorsEnum.ERROR,
      borderColor: ColorsEnum.ERROR,
    },
  },
  spinner: {
    width: "20px !important",
    height: "20px !important",
  },
};

export default styles;
