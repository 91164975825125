import axios from "axios";
import dayjs from "dayjs";
import { CreateMessageInterface } from "interfaces/CreateMessageInterface";

export const createMessage = async (data: CreateMessageInterface) => {
  const request = {
    route: String(data.trips),
    message: {
      vehicles: [],
      trips: [String(data.trips)],
      title: data.title,
      message: data.message,
      date: dayjs().format("YYYY-MM-DD"),
      timestamp: dayjs().format("YYYY-MM-DD[T]HH:mm:ssZ"),
      lastChange: dayjs().format("YYYY-MM-DD[T]HH:mm:ssZ"),
      startTimestamp: dayjs(data.startTimestamp).format("YYYY-MM-DD[T]HH:mm:ssZ"),
      endTimestamp: dayjs(data.endTimestamp).format("YYYY-MM-DD[T]HH:mm:ssZ"),
      priority: +data.priority,
      device: "DISPLAY",
      message_id: data.message_id,
      messageType: data.messageType,
    },
  };

  return await axios.post("https://mock-message-add-jfhsbbxa2a-ey.a.run.app", request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
