import React, { useEffect, useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import styles from "./styles";
import { IconBreadcrumbs } from "components/molecules/Breadcrumbs/Breadcrumbs";
import { RoutesEnum } from "enums/routes.enum";
import { UserInfo } from "components/atoms/UserInfo/UserInfo";
import { useParams } from "react-router-dom";
import { UserBox } from "components/molecules/UserBox/UserBox";
import PersonIcon from "@mui/icons-material/Person";
import { UserLogTable } from "components/organisms/UserLogTable/UserLogTable";
import { UserLogContextProvider } from "context/UserLogContext";
import { createCollection } from "utils/createCollection";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { UserLogInterface } from "interfaces/firestore/UserLogInterface";

export const UserLog = () => {
  const [data, setData] = useState<UserLogInterface | null>(null);
  const tableDataRef = createCollection<UserLogInterface>("userlog");
  const { userId } = useParams();

  useEffect(() => {
    let unsubscribe: () => void = () => {};

    const getData = async () => {
      const q = await query(tableDataRef, where(documentId(), "==", userId));
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const filteredData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setData(filteredData[0]);
      });
    };

    getData();

    return () => {
      unsubscribe();
    };
  }, []);

  if (!userId || !data) return null;

  return (
    <UserLogContextProvider>
      <Box>
        <Typography sx={styles.heading}>User Log</Typography>
        <IconBreadcrumbs path={[{ title: "Users", to: RoutesEnum.USERS }]} active="User Log" />
        <Box sx={styles.infoContainer}>
          <UserInfo title="User ID" content={data.userid} />
        </Box>
        <Stack spacing={2} direction="row" sx={styles.boxContainer}>
          <UserBox
            heading={data.display_name}
            date={data.registration_timestamp}
            icon={PersonIcon}
            info={[
              { heading: "Phone", content: data.phone_number },
              { heading: "Email", content: data.email },
            ]}
          />
        </Stack>
        <UserLogTable />
      </Box>
    </UserLogContextProvider>
  );
};
