import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  heading: {
    mb: 2,
    ml: 2.5,
    fontSize: 20,
    fontWeight: 500,
    color: ColorsEnum.HEADING,
  },
  infoContainer: {
    mt: 3,
    ml: 1.5,
    maxWidth: "fit-content",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  boxContainer: {
    mt: 3.5,
  },
};

export default styles;
