import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Card } from "components/atoms/Card/Card";
import { PeriodSelect } from "components/molecules/PeriodSelect/PeriodSelect";
import { IconBadge } from "components/atoms/IconBadge/IconBadge";
import { UsersIcon } from "assets/icons/UsersIcon";
import { CardRecord } from "components/molecules/CardRecord/CardRecord";
import { PeriodEnum } from "enums/period.enum";
import { ColorsEnum } from "enums/colors.enum";
import { createCollection } from "utils/createCollection";
import { UsersInterface } from "interfaces/firestore/UsersInterface";
import { Unsubscribe, onSnapshot, query } from "firebase/firestore";
// import { getPeriodRange } from "utils/getPeriodRange";

interface LoadingInterface {
  users: boolean;
  active: boolean;
}

export const UsersCard = () => {
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum.WEEK);
  const [usersCount, setUsersCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const tableDataRef = createCollection<UsersInterface>("user");
  const [loading, setLoading] = useState<LoadingInterface>({
    users: true,
    active: true,
  });

  useEffect(() => {
    setLoading({
      users: true,
      active: true,
    });
    const unsubscribers: Unsubscribe[] = [];

    const getData = async () => {
      // const periodRange = getPeriodRange(period);

      // const dateRangeParameters = [
      //   where("alarm_timestamp", ">=", periodRange.from),
      //   where("alarm_timestamp", "<=", periodRange.to),
      // ];

      const qUsers = await query(tableDataRef /*, ...dateRangeParameters*/);

      const unsubscribeUsers = onSnapshot(qUsers, async (snapshot) => {
        setUsersCount(snapshot.size);
        setLoading((prevState) => ({ ...prevState, users: false }));
      });

      const qActive = await query(
        tableDataRef
        // ...dateRangeParameters,
        // where("status", "==", "pending")
      );

      const unsubscribeActive = onSnapshot(qActive, async (snapshot) => {
        setActiveCount(snapshot.size || 0);
        setLoading((prevState) => ({ ...prevState, active: false }));
      });

      unsubscribers.push(unsubscribeUsers);
      unsubscribers.push(unsubscribeActive);
    };

    getData();

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [period]);

  const handlePeriodChange = (value: PeriodEnum) => {
    setPeriod(value);
  };

  const isLoading = loading.users || loading.active;

  return (
    <Card>
      <Box display="flex" justifyContent="space-between">
        <IconBadge
          Icon={UsersIcon}
          stroke="common.black"
          backgroundColor={ColorsEnum.WARNING_FADED}
        />
        <PeriodSelect value={period} onChange={handlePeriodChange} />
      </Box>
      <Box display="flex">
        <CardRecord heading="Users" content={usersCount} difference="--.--" isLoading={isLoading} />
        <CardRecord
          heading="Active"
          content={activeCount}
          difference="--.--"
          isLoading={isLoading}
        />
      </Box>
    </Card>
  );
};
