export enum RoutesEnum {
  HOME = "/",
  USERS = "/users",
  USER_LOG = "/users/:userId",
  MESSAGES = "/messages",
  MESSAGE = "/messages/:messageId",
  SILENT_ALARM = "/silent-alarm",
  ADMIN = "/admin",
  CREATE_MESSAGE = "/create-message",
  LOGIN = "/login",
  FORBIDDEN = "/forbidden",
}
