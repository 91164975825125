import React, { ReactElement, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  Button,
  Divider,
  Stack,
  TextField,
  InputAdornment,
  Chip,
} from "@mui/material";
import { FilterIcon } from "assets/icons/FilterIcon";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./styles";
import { FieldSelect } from "components/atoms/FieldSelect/FieldSelect";
import { formatDate } from "utils/formatDate";
import { getFieldTypeLabel } from "utils/getFieldTypeLabel";
import { FieldEnum } from "enums/field.enum";
import { DateRangeInterface } from "interfaces/DateRangeInterface";
import { FilterOptionsInterface } from "context/MessagesContext";
import { BulkActionButton } from "components/atoms/BulkActionButton/BulkActionButton";
import { BulkActionInterface } from "interfaces/BulkActionInterface";
// import { BulkActionButton } from "components/atoms/BulkActionButton/BulkActionButton";

interface FormValues {
  search: string;
}

interface Props {
  search: string;
  setSearch: (search: string) => void;
  setIsDateRandeModalOpen: (isDateRandeModalOpen: boolean) => void;
  setIsFilterModalOpen?: (isOpen: boolean) => void;
  dateRange: DateRangeInterface;
  setDateRange: (range: DateRangeInterface) => void;
  fieldType?: FieldEnum;
  searchType?: string;
  heading: string;
  filterOptions?: FilterOptionsInterface;
  setFilterOptions?: (options: FilterOptionsInterface) => void;
  bulkActions?: BulkActionInterface[];
  customComponent?: ReactElement;
}

export const TableHeader = ({
  search,
  setSearch,
  setIsDateRandeModalOpen,
  setIsFilterModalOpen,
  dateRange,
  setDateRange,
  fieldType,
  searchType,
  heading,
  filterOptions,
  setFilterOptions,
  bulkActions,
  customComponent,
}: Props) => {
  const { control, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      search,
    },
  });

  const searchValue = watch("search");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(searchValue);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  const handleDateFilterClick = () => {
    setIsDateRandeModalOpen(true);
  };

  const handleSearchDeletion = () => {
    setSearch("");
    setValue("search", "");
  };

  const handleDateDeletion = () => {
    setDateRange({});
  };

  const handleFilterDeletion = (filter: keyof FilterOptionsInterface) => {
    if (!filterOptions || !setFilterOptions) return;
    setFilterOptions({ ...filterOptions, [filter]: null });
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.heading}>{heading}</Typography>
      {customComponent}
      <Stack direction="row" spacing={1} sx={styles.chipContainer}>
        {search && (
          <Chip
            label={`${fieldType ? getFieldTypeLabel(fieldType) : searchType}: ${search}`}
            onClick={handleSearchDeletion}
            onDelete={handleSearchDeletion}
            variant="outlined"
          />
        )}
        {dateRange.from && dateRange.to && (
          <Chip
            label={`${formatDate(dateRange.from, false)} - ${formatDate(dateRange.to, false)}`}
            onClick={handleDateDeletion}
            onDelete={handleDateDeletion}
            variant="outlined"
          />
        )}
        {filterOptions?.priority && (
          <Chip
            label={`Priority: ${filterOptions.priority}`}
            onClick={() => handleFilterDeletion("priority")}
            onDelete={() => handleFilterDeletion("priority")}
            variant="outlined"
          />
        )}
        {filterOptions?.status && (
          <Chip
            label={`Status: ${filterOptions.status}`}
            onClick={() => handleFilterDeletion("status")}
            onDelete={() => handleFilterDeletion("status")}
            variant="outlined"
          />
        )}
        {filterOptions?.type && (
          <Chip
            label={`Type: ${filterOptions.type}`}
            onClick={() => handleFilterDeletion("type")}
            onDelete={() => handleFilterDeletion("type")}
            variant="outlined"
          />
        )}
      </Stack>
      <Stack sx={styles.stack} direction="row" spacing={1}>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <TextField
              sx={styles.search}
              id="search"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              {...field}
            />
          )}
        />
        {fieldType && <FieldSelect />}
        <Divider orientation="vertical" variant="fullWidth" flexItem sx={styles.divider} />
        <Button
          sx={styles.actionButton}
          size="small"
          color="inherit"
          variant="outlined"
          startIcon={<FilterIcon />}
          onClick={() => setIsFilterModalOpen && setIsFilterModalOpen(true)}
        >
          Filter
        </Button>
        <Button
          sx={styles.actionButton}
          size="small"
          color="inherit"
          variant="outlined"
          startIcon={<CalendarMonthIcon />}
          onClick={handleDateFilterClick}
          disabled={!!(search && fieldType === FieldEnum.ID)}
        >
          Filter
        </Button>
        {bulkActions && <BulkActionButton bulkActions={bulkActions} />}
      </Stack>
    </Box>
  );
};
