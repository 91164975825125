import React, { useEffect, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { Card } from "components/atoms/Card/Card";
import { Message } from "components/molecules/Message/Message";
import { createCollection } from "utils/createCollection";
import { MessagesInterface } from "interfaces/firestore/MessagesInterface";
import { Unsubscribe, limit, onSnapshot, query } from "firebase/firestore";

export const RecentMessagesCard = () => {
  const [recentMessages, setRecentMessages] = useState<MessagesInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const tableDataRef = createCollection<MessagesInterface>("messages");

  useEffect(() => {
    let unsubscriber: Unsubscribe | null = null;

    const getData = async () => {
      const q = await query(tableDataRef, limit(10));

      unsubscriber = onSnapshot(
        q,
        async (snapshot) => {
          const filteredData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setRecentMessages(filteredData);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    };

    getData();

    return () => {
      unsubscriber && unsubscriber();
    };
  }, []);

  return (
    <Card sx={{ p: 2.5, pr: 0, justifyContent: "flex-start" }}>
      <Box>
        <Typography color="common.black" fontSize={16} fontWeight={500} mb={2.5}>
          Recent Messages
        </Typography>
      </Box>
      <Stack divider={<Divider flexItem />} spacing={0} sx={{ pr: 2.5, overflowY: "auto" }}>
        {!loading &&
          recentMessages.map((message) => <Message key={message.message_id} message={message} />)}
      </Stack>
    </Card>
  );
};
