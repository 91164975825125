import React from "react";
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles";
import { ToggleButtons } from "components/molecules/ToggleButtons/ToggleButtons";
import { FilterOptionsInterface, useMessagesContext } from "context/MessagesContext";
import { useForm, Controller } from "react-hook-form";

export const MessageFilter = () => {
  const { filterOptions, setFilterOptions, setIsFilterModalOpen } = useMessagesContext();
  const { handleSubmit, control, setValue, watch } = useForm<FilterOptionsInterface>({
    defaultValues: filterOptions,
  });

  const handleClose = () => {
    setIsFilterModalOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    setFilterOptions(data);
    handleClose();
  });

  const status = watch("status");
  const priority = watch("priority");

  const handleReset = () => {
    setValue("priority", null);
    setValue("status", null);
    setValue("type", null);
  };

  return (
    <Dialog sx={styles.container} onClose={handleClose} open>
      <DialogTitle>
        Message Filter{" "}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box sx={styles.content}>
        <ToggleButtons
          heading="Status"
          data={["Live", "Pending", "Offline"]}
          active={status}
          onChange={(option: string) => setValue("status", option)}
          onClear={() => {
            setValue("status", null);
          }}
        />
        <ToggleButtons
          heading="Priority"
          data={["1", "2", "3"]}
          active={priority}
          onChange={(option: string) => setValue("priority", option)}
          onClear={() => {
            setValue("priority", null);
          }}
        />
        <Box sx={styles.types}>
          <Typography>Types</Typography>
          <Box sx={styles.buttonsContainer}>
            <ButtonBase onClick={() => setValue("type", null)}>Clear</ButtonBase>
          </Box>
        </Box>
        <FormControl>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  value="static"
                  control={<Radio />}
                  label="Static - global messages for all services."
                />
                <FormControlLabel
                  value="general"
                  control={<Radio />}
                  label="General - non-service related messages."
                />
                <FormControlLabel
                  value="specific"
                  control={<Radio />}
                  label="Specific - messages specific for a particular service."
                />
              </RadioGroup>
            )}
          ></Controller>
        </FormControl>
        <Box sx={styles.actionButtonsContainer}>
          <Button size="large" color="inherit" variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button
            size="large"
            color="inherit"
            variant="contained"
            sx={styles.apply}
            onClick={onSubmit}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
