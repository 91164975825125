import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { WarningIcon } from "assets/icons/WarningIcon";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box } from "@mui/material";
import { Status } from "components/atoms/Status/Status";
import { MessageStatusEnum } from "enums/status.enum";
import { ColorsEnum } from "enums/colors.enum";
import { MessagesInterface } from "interfaces/firestore/MessagesInterface";
import { formatDate } from "utils/formatDate";

interface Props {
  message: MessagesInterface;
}

export const Message = ({ message }: Props) => {
  return (
    <Accordion
      sx={{
        boxShadow: "unset",
        "&.Mui-expanded": { margin: 0 },
        "&:before": {
          content: "unset",
        },
      }}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          "& .MuiAccordionSummary-content": { display: "flex", alignItems: "center" },
          "& .Mui-expanded": { mt: 1.5 },
        }}
      >
        <Box sx={{ mr: 1, padding: 1, display: "flex", alignItems: "center" }}>
          {message.status === MessageStatusEnum.PENDING ? (
            <WarningIcon sx={{ width: 28, height: 28 }} />
          ) : (
            <CheckCircleRoundedIcon sx={{ width: 28, height: 28 }} color="success" />
          )}
        </Box>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontSize={14} fontWeight={400} color={ColorsEnum.HEADING}>
              {message.messageType}
            </Typography>
            <Typography fontSize={12} fontWeight={400} color="#A6A8B1">
              {formatDate(message.endTimestamp, false)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontSize={14} fontWeight={500} color="#33343A">
              {message.title}
            </Typography>
            <Status message={message.status} />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ pl: 7.5 }}>
        <Typography fontSize={12} fontWeight={400}>
          {message.content}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
