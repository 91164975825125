import React from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import styles from "./styles";
import { getDifference } from "utils/getDifference";
import { spreadSx } from "utils/spreadSx";
import { WarningIcon } from "assets/icons/WarningIcon";
import { ColorsEnum } from "enums/colors.enum";

interface Props {
  heading: string;
  content: string | number;
  difference?: number | string;
  white?: boolean;
  error?: boolean;
  warning?: boolean;
  isLoading?: boolean;
}

export const CardRecord = ({
  heading,
  content,
  difference = 0,
  white = false,
  error = false,
  warning = false,
  isLoading = false,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Typography
        sx={spreadSx(
          styles.heading,
          error && { color: ColorsEnum.ERROR_DARK },
          white && { color: "common.white" }
        )}
      >
        {heading}
      </Typography>
      <Box display="flex" alignItems="center">
        {isLoading ? (
          <Skeleton variant="rectangular" width={35} height={30} />
        ) : (
          <Typography sx={spreadSx(styles.content, white && { color: "common.white" })}>
            {content}
          </Typography>
        )}
        {warning && <WarningIcon sx={{ ml: 1, fontSize: 20 }} />}
        {difference !== 0 && (
          <Typography
            sx={styles.difference}
            color={
              white
                ? "#DBDEEE"
                : typeof difference === "number" && difference < 0
                ? "error.main"
                : "success.main"
            }
          >
            {typeof difference === "number" ? getDifference(difference) : `+${difference}%`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
