import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ColorsEnum } from "enums/colors.enum";

enum CategoryEnum {
  CAPACITY = "Capacity",
  OTHER_OPTION1 = "Other Option 1",
  OTHER_OPTION2 = "Other Option 2",
}

const menuItems = [CategoryEnum.CAPACITY, CategoryEnum.OTHER_OPTION1, CategoryEnum.OTHER_OPTION2];

export const CategorySelect = () => {
  const [selectedValue, setSelectedValue] = useState<CategoryEnum>(CategoryEnum.CAPACITY);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: CategoryEnum) => {
    setSelectedValue(index);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={{
          textTransform: "unset",
          fontWeight: 400,
          background: `${ColorsEnum.PRIMARY}14`,
          color: ColorsEnum.PRIMARY,
          fontSize: 14,
          py: 0.5,
          px: 1.5,
        }}
        size="small"
        variant="text"
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              rotate: open ? "180deg" : "unset",
              fontSize: "22px !important",
            }}
          />
        }
        onClick={handleClick}
      >
        {selectedValue}
      </Button>
      <Menu id="period-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map((item) => (
          <MenuItem dense key={item} onClick={(event) => handleMenuItemClick(event, item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
