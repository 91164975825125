import React, { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children: ReactNode;
  lines: number;
}

export const TextClamp = ({ children, lines }: Props) => (
  <Typography
    component="p"
    sx={{
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      whiteSpace: "pre-wrap",
      "-webkit-line-clamp": String(lines),
      lineHeight: 1.5,
      maxHeight: `calc(${lines}em * 1.5)`,
    }}
    fontSize="inherit"
    fontWeight="inherit"
    color="inherit"
  >
    {children}
  </Typography>
);
