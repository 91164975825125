import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import styles from "./styles";
import { SidebarItem } from "components/molecules/SidebarItem/SidebarItem";
import { RoutesEnum } from "enums/routes.enum";

export const Sidebar = () => (
  <Box component="aside" bgcolor="primary" sx={styles.container}>
    <Stack spacing={1}>
      <SidebarItem to={RoutesEnum.HOME}>Home</SidebarItem>
      <SidebarItem to={RoutesEnum.USERS}>Users</SidebarItem>
      <SidebarItem to={RoutesEnum.MESSAGES}>Messages</SidebarItem>
      <SidebarItem to={RoutesEnum.SILENT_ALARM}>Silent Alarm</SidebarItem>
      <SidebarItem to={RoutesEnum.ADMIN}>Admin</SidebarItem>
    </Stack>
    <Typography sx={styles.version}>
      {process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT} v{process.env.REACT_APP_VERSION}
    </Typography>
  </Box>
);
