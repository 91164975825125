import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  headContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default styles;
