import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  label: {
    mb: 1.75,
    fontWeight: 400,
    fontSize: 18,
    color: "#5A5A5D",
  },
};

export default styles;
