import React, { ReactNode, useState, useEffect } from "react";
import {
  getCountFromServer,
  query,
  where,
  documentId,
  limit,
  QueryDocumentSnapshot,
  startAfter,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { useDidUpdate } from "utils/useDidUpdate";
import { createCollection } from "utils/createCollection";
import { SilentAlarmInterface } from "interfaces/firestore/SilentAlarmsInterface";
import { DateRangeInterface } from "interfaces/DateRangeInterface";
import { FieldEnum } from "enums/field.enum";

interface SilentAlarmContextInterface {
  isLoading: boolean;
  isDateRandeModalOpen: boolean;
  search: string;
  fieldType: FieldEnum;
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
  data: SilentAlarmInterface[];
  dateRange: DateRangeInterface;
  setIsLoading: (isLoading: boolean) => void;
  setIsDateRandeModalOpen: (isDateRandeModalOpen: boolean) => void;
  setCurrentPage: (currentPage: number) => void;
  setTotalItems: (items: number) => void;
  setSearch: (search: string) => void;
  setFieldType: (field: FieldEnum) => void;
  setDateRange: (range: DateRangeInterface) => void;
  setLoadMoreFlag: (loadMore: boolean) => void;
}

const defaultValues = {
  isLoading: true,
  isDateRandeModalOpen: false,
  search: "",
  fieldType: FieldEnum.ID,
  itemsPerPage: 1,
  currentPage: 1,
  totalItems: 1,
  data: [],
  dateRange: {},
  setIsLoading: () => {},
  setIsDateRandeModalOpen: () => {},
  setCurrentPage: () => {},
  setTotalItems: () => {},
  setSearch: () => {},
  setFieldType: () => {},
  setDateRange: () => {},
  setLoadMoreFlag: () => {},
};

export const SilentAlarmContext = React.createContext<SilentAlarmContextInterface>(defaultValues);

export const useSilentAlarmContext = () => React.useContext(SilentAlarmContext);

interface Props {
  children: ReactNode;
}

const itemsPerPage = 15;
let wasInputTouched = false;
let documentSnapshots: QueryDocumentSnapshot<SilentAlarmInterface>[] = [];
let subscribers: (() => void)[] = [];

export const SilentAlarmContextProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDateRandeModalOpen, setIsDateRandeModalOpen] = useState(false);
  const [fieldType, setFieldType] = useState(FieldEnum.ID);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState<DateRangeInterface>({});
  const [totalItems, setTotalItems] = useState(3282);
  const [search, setSearch] = useState("");
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const tableDataRef = createCollection<SilentAlarmInterface>("alarm-messages");
  const [data, setData] = useState<SilentAlarmInterface[][]>([]);

  const getData = async (recount = false, loadMore = false) => {
    !loadMore && setIsLoading(true);
    const parameters = [];

    if (!loadMore) {
      subscribers.forEach((subscriber) => subscriber());
      subscribers = [];
    }

    if (search) {
      if (fieldType === FieldEnum.ID) {
        parameters.push(where(documentId(), "==", search));
        dateRange.from && dateRange.to && setDateRange({});
      } else {
        parameters.push(where(fieldType, "==", search));
      }
    }

    if (dateRange.from && dateRange.to) {
      if (search && fieldType === FieldEnum.ID) return;
      parameters.push(where("alarm_timestamp", ">=", dateRange.from));
      parameters.push(where("alarm_timestamp", "<=", dateRange.to));
    }

    if (!search || (search && fieldType !== FieldEnum.ID))
      parameters.push(orderBy("alarm_timestamp", "desc"));

    if (loadMore) {
      const lastVisible = documentSnapshots[documentSnapshots.length - 1];
      parameters.push(startAfter(lastVisible));
    }

    const q = await query(tableDataRef, ...parameters, limit(itemsPerPage));
    const unsubscribeGenerator = (index: number) =>
      onSnapshot(q, (querySnapshot) => {
        documentSnapshots = [...querySnapshot.docs];
        const filteredData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        const duplicatedData = [...data];
        duplicatedData[index] = filteredData;
        setData(duplicatedData);
      });

    if (loadMore) {
      const unsubscribe = unsubscribeGenerator(data.length);
      subscribers.push(unsubscribe);
    } else {
      const unsubscribe = unsubscribeGenerator(0);
      subscribers.push(unsubscribe);
    }

    if (recount) {
      const qCount = await query(tableDataRef, ...parameters);
      const snapshot = await getCountFromServer(qCount);
      setTotalItems(snapshot.data().count);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData(true);
  }, []);

  useDidUpdate(() => {
    getData(true);
  }, [dateRange]);

  useEffect(() => {
    if (isLoading || !loadMoreFlag) {
      setLoadMoreFlag(false);
      return;
    }
    if (totalItems === data.flat().length) {
      setLoadMoreFlag(false);
      return;
    }
    getData(false, true);
    setLoadMoreFlag(false);
  }, [loadMoreFlag]);

  useDidUpdate(() => {
    if (search) {
      wasInputTouched = true;
    }
    if (!search && !wasInputTouched) return;
    if (!search && wasInputTouched) {
      getData(true);
      return;
    }
    getData(true);
  }, [search, fieldType]);

  return (
    <SilentAlarmContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isDateRandeModalOpen,
        setIsDateRandeModalOpen,
        itemsPerPage,
        currentPage,
        setCurrentPage,
        totalItems,
        setTotalItems,
        search,
        setSearch,
        fieldType,
        setFieldType,
        data: data.flat(),
        dateRange,
        setDateRange,
        setLoadMoreFlag,
      }}
    >
      {children}
    </SilentAlarmContext.Provider>
  );
};
