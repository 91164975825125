import { styled } from "@mui/material";
import { ColorsEnum } from "enums/colors.enum";
import { NavLink } from "react-router-dom";

export const SidebarItem = styled(NavLink)(() => ({
  color: "white",
  textDecoration: "none",
  fontWeight: 700,
  fontSize: "14px",

  "&.active": {
    color: ColorsEnum.NAV_ACTIVE,
  },
}));
