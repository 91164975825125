import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Button, OutlinedInput } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RoutesEnum } from "enums/routes.enum";
import { useAuthContext } from "context/AuthContext";
import logo from "assets/logo.svg";
import styles from "./styles";
import Typography from "@mui/material/Typography";
import { Label } from "components/atoms/Label/Label";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/VisibilityOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";
import { ErrorEnum } from "enums/error.enum";

interface FormValues {
  email: string;
  password: string;
}

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { signIn } = useAuthContext();
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleClickShowPassword = () => {
    inputRef.current?.blur();
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    try {
      setError("");
      await signIn(data.email, data.password);
      navigate(RoutesEnum.HOME);
    } catch (error: any) {
      switch (error.code) {
        case ErrorEnum.NO_ACCESS:
          setError(`Authorised access Required. 
          Please contact your System Administrator.`);
          break;
        case ErrorEnum.WRONG_PASSWORD:
          setError(`Authorised access Required. 
          Please register via the TUA app.`);
          break;
        default:
          setError(`Unexpected error.
          Try again later.`);
          break;
      }
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Box sx={styles.container}>
      <Box sx={styles.panel}>
        <Box component="img" src={logo} sx={styles.logo} />
        <Typography sx={styles.text}>
          Welcome.
          <br />
          To your Train Usage
          <br />
          management system!
        </Typography>
        <Typography sx={styles.version}>
          {process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT} v{process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
      <Box sx={styles.formContainer}>
        <Box sx={styles.item}>
          <Typography sx={styles.heading}>Login</Typography>
          <Box component="form" onSubmit={onSubmit} display="flex" flexDirection="column">
            <Box sx={styles.section}>
              <Label>Email</Label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    sx={styles.input}
                    placeholder="Enter your email"
                    type="email"
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.section}>
              <Label>Password</Label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    sx={styles.input}
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    {...field}
                    inputRef={inputRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
            </Box>
            <Button sx={styles.button} variant="contained" type="submit" disabled={isLoading}>
              Login now
            </Button>

            {error && <Typography sx={styles.error}>{error}</Typography>}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
