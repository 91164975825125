import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    maxWidth: 420,
    px: 2,
    py: 1.5,
    flex: 1,
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
  },
  iconContainer: {
    width: 45,
    height: 45,
    mr: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: ColorsEnum.WARNING_FADED,
    borderRadius: 2,
  },
  icon: {
    fontSize: 28,
  },
  dataContainer: {
    flex: 1,
    alignSelf: "center",
  },
  name: {
    fontSize: 14,
    fontWeight: 400,
    color: ColorsEnum.TEXT_SECONDARY,
  },
  since: {
    fontSize: 12,
    fontWeight: 400,
    color: ColorsEnum.TEXT_SECONDARY,
  },
  date: {
    fontSize: "inherit",
    fontWeight: "inherit",
    color: "text.primary",
  },
  infoContainer: {
    mt: 4,
    display: "flex",
  },
  info: {
    flex: 1,
    px: 0.5,
  },
  infoHeading: {
    mb: 0.5,
    fontSize: 12,
    fontWeight: 400,
    color: ColorsEnum.TEXT_SECONDARY,
  },
  infoContent: {
    fontSize: 14,
    fontWeight: 500,
    color: ColorsEnum.HEADING,
  },
};
export default styles;
