import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  heading: {
    marginBottom: 1,
    fontSize: 13,
    color: ColorsEnum.TEXT_SECONDARY,
    fontWeight: 400,
  },
  content: {
    fontSize: 20,
    color: ColorsEnum.HEADING,
    fontWeight: 500,
  },
  difference: {
    ml: 1,
    fontSize: 12,
    fontWeight: 400,
  },
};

export default styles;
