import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  select: {
    "& .MuiSelect-select": {
      py: "5px",
      fontSize: 12,
    },
  },
  option: {
    fontSize: 14,
  },
};

export default styles;
