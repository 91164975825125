import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    pl: 2.5,
    borderTop: "1px solid #F1F3F9",
  },
  item: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
  },
};

export default styles;
