import React from "react";
import { GlobalStyles } from "@mui/material";

export const GlobalStyle = () => (
  <GlobalStyles
    styles={{
      "*, *::before, *::after": {
        boxSizing: "border-box",
        fontFamily: "Inter, sans-serif",
      },
      "#root": {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      },
      "input[disabled]": {
        cursor: "not-allowed",
      },
    }}
  />
);
