import * as React from "react";
import { Typography, Breadcrumbs, Link as MuiLink } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import styles from "./styles";
import { Link } from "react-router-dom";
import { RoutesEnum } from "enums/routes.enum";
import { BreadcrumbInterface } from "interfaces/BreadcrumbInterface";

interface Props {
  path: BreadcrumbInterface[];
  active: string;
}

export const IconBreadcrumbs = ({ path, active }: Props) => {
  return (
    <Breadcrumbs sx={styles.container}>
      <MuiLink component={Link} to={RoutesEnum.HOME} sx={styles.item} underline="hover">
        <HomeIcon fontSize="small" />
      </MuiLink>
      {path.map((item, i) => (
        <MuiLink key={i} component={Link} to={item.to} sx={styles.item} underline="hover">
          {item.title}
        </MuiLink>
      ))}
      <Typography sx={styles.item}>{active}</Typography>
    </Breadcrumbs>
  );
};
