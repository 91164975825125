import { darken } from "@mui/material";
import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    minWidth: "fit-content",
    paddingX: 3,
    paddingY: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexBasis: 175,
    backgroundColor: ColorsEnum.NAV,
    borderTop: "1px solid",
    borderColor: darken(ColorsEnum.NAV, 0.1),
  },
  version: {
    width: "100%",
    textAlign: "right",
    color: "white",
    opacity: 0.3,
    fontSize: 12,
  },
};

export default styles;
