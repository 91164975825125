import React, { useState, useEffect } from "react";
import {
  getCountFromServer,
  query,
  where,
  limit,
  QueryDocumentSnapshot,
  startAfter,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { useDidUpdate } from "utils/useDidUpdate";
import { createCollection } from "utils/createCollection";
import { UsersInterface } from "interfaces/firestore/UsersInterface";
import { DateRangeInterface } from "interfaces/DateRangeInterface";
import { Outlet } from "react-router-dom";

interface UsersContextInterface {
  isLoading: boolean;
  isDateRandeModalOpen: boolean;
  search: string;
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
  data: UsersInterface[];
  dateRange: DateRangeInterface;
  selected: string[];
  setIsLoading: (isLoading: boolean) => void;
  setIsDateRandeModalOpen: (isDateRandeModalOpen: boolean) => void;
  setCurrentPage: (currentPage: number) => void;
  setTotalItems: (items: number) => void;
  setSearch: (search: string) => void;
  setDateRange: (range: DateRangeInterface) => void;
  setLoadMoreFlag: (loadMore: boolean) => void;
  handleSelect: (rowId: string) => void;
}

const defaultValues = {
  isLoading: true,
  isDateRandeModalOpen: false,
  search: "",
  itemsPerPage: 1,
  currentPage: 1,
  totalItems: 1,
  data: [],
  dateRange: {},
  selected: [],
  setIsLoading: () => {},
  setIsDateRandeModalOpen: () => {},
  setCurrentPage: () => {},
  setTotalItems: () => {},
  setSearch: () => {},
  setDateRange: () => {},
  setLoadMoreFlag: () => {},
  handleSelect: () => {},
};

export const UsersContext = React.createContext<UsersContextInterface>(defaultValues);

export const useUsersContext = () => React.useContext(UsersContext);

const itemsPerPage = 15;
let wasInputTouched = false;
let documentSnapshots: QueryDocumentSnapshot<UsersInterface>[] = [];
let subscribers: (() => void)[] = [];

export const UsersContextProvider = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDateRandeModalOpen, setIsDateRandeModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState<DateRangeInterface>({});
  const [totalItems, setTotalItems] = useState(3282);
  const [search, setSearch] = useState("");
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const tableDataRef = createCollection<UsersInterface>("user");
  const [data, setData] = useState<UsersInterface[][]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const getData = async (recount = false, loadMore = false) => {
    !loadMore && setIsLoading(true);
    const parameters = [];

    if (!loadMore) {
      subscribers.forEach((subscriber) => subscriber());
      subscribers = [];
    }

    if (search) {
      parameters.push(where("userid", "==", search));
      dateRange.from && dateRange.to && setDateRange({});
    }

    if (dateRange.from && dateRange.to) {
      parameters.push(where("registration_timestamp", ">=", dateRange.from));
      parameters.push(where("registration_timestamp", "<=", dateRange.to));
    }

    parameters.push(orderBy("registration_timestamp", "desc"));

    if (loadMore) {
      const lastVisible = documentSnapshots[documentSnapshots.length - 1];
      parameters.push(startAfter(lastVisible));
    }

    try {
      const q = await query(tableDataRef, ...parameters, limit(itemsPerPage));
      const unsubscribeGenerator = (index: number) =>
        onSnapshot(q, (querySnapshot) => {
          documentSnapshots = [...querySnapshot.docs];
          const filteredData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          const duplicatedData = [...data];
          duplicatedData[index] = filteredData;
          setData(duplicatedData);
        });

      if (loadMore) {
        const unsubscribe = unsubscribeGenerator(data.length);
        subscribers.push(unsubscribe);
      } else {
        const unsubscribe = unsubscribeGenerator(0);
        subscribers.push(unsubscribe);
      }

      if (recount) {
        const qCount = await query(tableDataRef, ...parameters);
        const snapshot = await getCountFromServer(qCount);
        setTotalItems(snapshot.data().count);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData(true);
  }, []);

  useDidUpdate(() => {
    getData(true);
  }, [dateRange]);

  useEffect(() => {
    if (isLoading || !loadMoreFlag) {
      setLoadMoreFlag(false);
      return;
    }
    if (totalItems === data.flat().length) {
      setLoadMoreFlag(false);
      return;
    }
    getData(false, true);
    setLoadMoreFlag(false);
  }, [loadMoreFlag]);

  useDidUpdate(() => {
    if (search) {
      wasInputTouched = true;
    }
    if (!search && !wasInputTouched) return;
    if (!search && wasInputTouched) {
      getData(true);
      return;
    }
    getData(true);
  }, [search]);

  const handleSelect = (rowId: string) => {
    const existsInArray = selected.some((id) => id === rowId);
    if (!existsInArray) {
      setSelected((prevState) => [...prevState, rowId]);
      return;
    }
    const newArray = selected.filter((id) => id !== rowId);
    setSelected(newArray);
  };

  return (
    <UsersContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isDateRandeModalOpen,
        setIsDateRandeModalOpen,
        itemsPerPage,
        currentPage,
        setCurrentPage,
        totalItems,
        setTotalItems,
        search,
        setSearch,
        data: data.flat(),
        dateRange,
        setDateRange,
        setLoadMoreFlag,
        selected,
        handleSelect,
      }}
    >
      <Outlet />
    </UsersContext.Provider>
  );
};
