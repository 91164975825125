import * as React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./styles";

interface Props {
  title: string;
  content: string;
}

export const UserInfo = ({ title, content }: Props) => (
  <Box sx={styles.container}>
    <Typography sx={styles.title}>{title}</Typography>
    <Typography sx={styles.content}>{content}</Typography>
  </Box>
);
