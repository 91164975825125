import React from "react";
import { Box } from "@mui/material";
import { AlarmTable } from "components/organisms/AlarmTable/AlarmTable";
import { SilentAlarmContextProvider } from "context/SilentAlarmContext";

export const SilentAlarm = () => (
  <SilentAlarmContextProvider>
    <Box>
      <AlarmTable />
    </Box>
  </SilentAlarmContextProvider>
);
