import dayjs from "dayjs";
import { PeriodEnum } from "enums/period.enum";
import { DateRangeInterface } from "interfaces/DateRangeInterface";

export const getPeriodRange = (period: PeriodEnum): DateRangeInterface => {
  switch (period) {
    case PeriodEnum.TODAY:
      return {
        from: dayjs().startOf("d").valueOf(),
        to: dayjs().endOf("d").valueOf(),
      };

    case PeriodEnum.WEEK:
      return {
        from: dayjs().subtract(1, "week").startOf("d").valueOf(),
        to: dayjs().endOf("d").valueOf(),
      };
    default:
      return {
        from: dayjs().subtract(1, "month").startOf("d").valueOf(),
        to: dayjs().endOf("d").valueOf(),
      };
  }
};
