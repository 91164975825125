import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "&:nth-child(n+3)": {
      borderLeft: "solid 1px #404042",
    },
  },
  header: {
    pb: 1.25,
    fontSize: {
      xs: 16,
      lg: 18,
    },
    fontWeight: 400,
    lineHeight: "22px",
    color: "#5B667C",
    textAlign: "center",
  },
  content: {
    fontSize: {
      xs: 16,
      lg: 18,
    },
    fontWeight: 400,
    color: "#9FA7B1",
    textAlign: "center",
  },
  category: {
    width: "100%",
    fontSize: {
      xs: 13,
      lg: 16,
    },
    fontWeight: 400,
    textAlign: "center",
    lineHeight: "19px",
    position: "absolute",
    transform: "translateY(-30px)",
    color: "#5B667C",
  },
};
export default styles;
