import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    pt: 4.5,
    pb: 3,
    zIndex: 1,
    background: "#ddd", // TODO remove after firebase integration
  },
  grid: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: {
      xs: "40px repeat(5, minmax(min-content, 1fr))",
      lg: "50px repeat(6, minmax(min-content, 1fr))",
      xl: "50px repeat(7, minmax(min-content, 1fr))",
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  header: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    textAlign: "center",
    lineHeight: "19px",
    color: "#5B667C",
    transform: "rotate(-90deg)",
  },
};

export default styles;
