import React from "react";
import { AppBar, Button, Toolbar } from "@mui/material";
import logo from "assets/logo.svg";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import ButtonBase from "@mui/material/ButtonBase";
import { useAuthContext } from "context/AuthContext";

export const Navbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const showHamburger = useMediaQuery(theme.breakpoints.down("md"));
  const { signOut } = useAuthContext();

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleHamburgerClick = () => {
    navigate("#menu");
  };

  return (
    <AppBar position="static" sx={{ zIndex: 1 }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <ButtonBase sx={{ height: "100%" }} onClick={handleLogoClick} focusRipple centerRipple>
          <Box
            component="img"
            src={logo}
            sx={{
              height: {
                xs: 27,
                md: "auto",
              },
            }}
          />
        </ButtonBase>
        {!showHamburger && (
          <Button variant="outlined" color="inherit" size="small" onClick={signOut}>
            Logout
          </Button>
        )}
        {showHamburger && (
          <IconButton size="large" edge="start" color="inherit" onClick={handleHamburgerClick}>
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};
