import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    px: 1.5,
    display: "grid",
    gap: 1,
    gridTemplateColumns: "auto auto auto auto",
    position: "relative",
    borderLeft: "solid 1px #404042",
  },
  heading: {
    position: "absolute",
    color: "#9FA7B1",
    transform: "translateY(-30px)",
  },
  text: {
    color: "#5B667C",
  },
  trendContainer: {
    display: "flex",
    alignItems: "center",
  },
  arrow: {
    width: 10,
    height: 16,
  },
  trend: {
    ml: "3px",
    fontSize: 12,
    color: "#C1292E",
  },
};
export default styles;
