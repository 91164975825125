import React, { ComponentType } from "react";
import { Box, SvgIconProps } from "@mui/material";

interface Props {
  Icon: ComponentType<SvgIconProps>;
  fill?: string;
  stroke?: string;
  backgroundColor: string;
}

export const IconBadge = ({
  Icon,
  fill = "transparent",
  stroke = "unset",
  backgroundColor,
}: Props) => (
  <Box
    sx={{
      width: 36,
      height: 36,
      backgroundColor,
      borderRadius: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Icon
      sx={{
        fill,
        stroke,
      }}
    />
  </Box>
);
