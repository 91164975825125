import React, { FormEvent, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormHelperText,
  Chip,
  Select,
  SelectChangeEvent,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import styles from "./styles";
import { ToggleButtons } from "components/molecules/ToggleButtons/ToggleButtons";
import { DatePicker } from "@mui/x-date-pickers";
import { spreadSx } from "utils/spreadSx";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateMessageInterface } from "interfaces/CreateMessageInterface";
import { createMessage } from "services/createMessage";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firestore";
import { predefinedMessages } from "./predefinedMessages";

const schema = yup.object().shape({
  trips: yup.number().typeError("Value must be a number").required(),
  priority: yup.string().required(),
  messageType: yup.string().required("Required"),
  title: yup.string().required("Required"),
  message: yup.string().required("Required"),
  startTimestamp: yup.date().typeError("Invalid Date").required(),
  endTimestamp: yup
    .date()
    .typeError("Invalid Date")
    .min(yup.ref("startTimestamp"), "End date can't be before start date")
    .required(),
});

const defaultValues: CreateMessageInterface = {
  message_id: Date.now(),
  trips: "",
  priority: "2",
  messageType: null,
  title: "",
  message: "",
  startTimestamp: dayjs(),
  endTimestamp: dayjs(),
};

export const CreateMessage = () => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateMessageInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [search, setSearch] = useState("");
  const [searchedId, setSearchedId] = useState("");
  const [doesIdExist, setDoesIdExist] = useState(true);
  const [selectValue, setSelectValue] = useState<keyof typeof predefinedMessages | "">("");
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState("");

  const priority = watch("priority");

  const onSubmit = handleSubmit(async (data) => {
    if ("getTime" in data.startTimestamp && "getTime" in data.endTimestamp) {
      setError("");
      setIsSending(true);
      try {
        await createMessage(data);
        handleIdReset();
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsSending(false);
      }
    }
  });

  const handleSelectChange = (event: SelectChangeEvent<keyof typeof predefinedMessages>) => {
    const value = event.target.value as keyof typeof predefinedMessages;
    setSelectValue(value);
    if (!value) return;
    setValue("title", predefinedMessages[value].title);
    setValue("message", predefinedMessages[value].message);
    setValue("messageType", predefinedMessages[value].messageType);
  };

  const handleSearchSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (!search) {
      return handleIdReset();
    }

    const docRef = doc(db, "messages", search);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      reset(defaultValues);
      const data = docSnap.data();
      setSearchedId(data.message_id);
      setValue("message_id", data.message_id);
      setValue("priority", String(data.priority));
      setValue("messageType", data.messageType);
      setValue("title", data.title);
      setValue("message", data.content);
      setValue("startTimestamp", dayjs(data.startTimestamp));
      setValue("endTimestamp", dayjs(data.endTimestamp));

      if (data.trips.length === 1) {
        setValue("trips", data.trips[0]);
      }
    } else {
      setDoesIdExist(false);
    }
  };

  const handleIdReset = () => {
    setDoesIdExist(true);
    setSearch("");
    setSearchedId("");
    defaultValues.message_id = Date.now();
    reset(defaultValues);
  };

  console.log("++ error", error);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.heading}>Create New Message</Typography>
      <Box sx={styles.find}>
        <Typography mr={2}>Find Message</Typography>
        <Box sx={styles.searchContainer} component="form" onSubmit={handleSearchSubmit}>
          <TextField
            sx={styles.search}
            id="search"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            value={search}
            onChange={(ev) => {
              setSearch(ev.target.value);
              setDoesIdExist(true);
            }}
            error={!doesIdExist}
          />
          {!doesIdExist && (
            <Typography color="error" ml={2}>
              ID doesn&apos;t exist
            </Typography>
          )}

          {searchedId && (
            <Chip
              sx={{ ml: 2 }}
              label={`Message ID: ${searchedId}`}
              variant="outlined"
              onClick={handleIdReset}
              onDelete={handleIdReset}
            />
          )}
        </Box>
      </Box>
      <Box component="form" sx={styles.form} onSubmit={onSubmit}>
        <Box sx={styles.column}>
          <Box sx={styles.field}>
            <Typography sx={styles.label}>Message ID</Typography>
            <Controller
              name="message_id"
              control={control}
              render={({ field }) => (
                <TextField label="" variant="outlined" sx={styles.input} disabled {...field} />
              )}
            />
          </Box>
          <Box sx={styles.field}>
            <Typography sx={styles.label}>Vehicles</Typography>
            <TextField label="" variant="outlined" sx={styles.input} disabled />
          </Box>
          <Box sx={styles.field}>
            <Typography sx={styles.label}>Trip</Typography>
            <Controller
              name="trips"
              control={control}
              render={({ field }) => (
                <TextField
                  label=""
                  variant="outlined"
                  sx={styles.input}
                  helperText={errors?.trips?.message}
                  error={!!errors?.trips?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <Box sx={styles.field}>
            <ToggleButtons
              heading="Priority"
              data={["1", "2", "3"]}
              active={priority}
              onChange={(option: string) => setValue("priority", option)}
            />
          </Box>
          <Box sx={styles.field}>
            <Typography sx={styles.label}>Type</Typography>
            <FormControl
              error={!!errors.messageType?.message}
              sx={errors.messageType?.message ? styles.error : {}}
            >
              <Controller
                control={control}
                name="messageType"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="STATIC"
                      control={<Radio />}
                      label="Static - global messages for all services."
                    />
                    <FormControlLabel
                      value="GENERAL"
                      control={<Radio />}
                      label="General - non-service related messages."
                    />
                    <FormControlLabel
                      value="SPECIFIC"
                      control={<Radio />}
                      label="Specific - messages specific for a particular service."
                    />
                  </RadioGroup>
                )}
              ></Controller>
              {errors.messageType?.message && (
                <FormHelperText id="component-error-text">
                  {errors.messageType.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
        <Box sx={styles.column}>
          <Box sx={styles.field}>
            <Typography sx={styles.label}>Predefined messages</Typography>
            <Select sx={styles.select} value={selectValue} onChange={handleSelectChange}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {(Object.keys(predefinedMessages) as Array<keyof typeof predefinedMessages>).map(
                (key) => (
                  <MenuItem key={key} value={key}>
                    {predefinedMessages[key].label}
                  </MenuItem>
                )
              )}
            </Select>
          </Box>
          <Box sx={styles.field}>
            <Typography sx={styles.label}>Title</Typography>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  label=""
                  variant="outlined"
                  sx={styles.input}
                  error={!!errors?.title?.message}
                  helperText={errors?.title?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <Box sx={styles.field}>
            <Typography sx={styles.label}>Message</Typography>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField
                  label=""
                  variant="outlined"
                  sx={styles.input}
                  multiline
                  rows={5}
                  placeholder="Message Note"
                  error={!!errors?.message?.message}
                  helperText={errors?.message?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <Box sx={spreadSx(styles.field, styles.pickers)}>
            <Box>
              <Typography sx={styles.label}>Start Date</Typography>
              <Controller
                name="startTimestamp"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    sx={styles.input}
                    format="DD/MM/YYYY"
                    label=""
                    slotProps={{
                      textField: {
                        ...(errors.startTimestamp?.message && {
                          helperText: errors.startTimestamp.message,
                          sx: spreadSx(styles.input, styles.dateError),
                        }),
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box>
              <Typography sx={styles.label}>End Date</Typography>
              <Controller
                name="endTimestamp"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    sx={styles.input}
                    format="DD/MM/YYYY"
                    label=""
                    slotProps={{
                      textField: {
                        ...(errors.endTimestamp?.message && {
                          helperText: errors.endTimestamp.message,
                          sx: spreadSx(styles.input, styles.dateError),
                        }),
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={spreadSx(styles.field, styles.buttons)}>
            <Button color="inherit" variant="outlined" size="large">
              Cancel
            </Button>
            <Button
              color="inherit"
              variant="contained"
              size="large"
              sx={styles.button}
              type="submit"
              disabled={isSending}
              startIcon={isSending && <CircularProgress sx={styles.spinner} color="inherit" />}
            >
              {searchedId ? "Update" : "Create"}
            </Button>
          </Box>
          {error && (
            <Typography color="error" textAlign="center">
              {error}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
