import React, { useEffect, useState } from "react";
import { Card } from "components/atoms/Card/Card";
import { DoughnutChart } from "components/molecules/DoughnutChart/DoughnutChart";
import { PeriodSelect } from "components/molecules/PeriodSelect/PeriodSelect";
import { Box, Typography } from "@mui/material";
import { LegendItem } from "components/atoms/LegendItem/LegendItem";
import { ColorsEnum } from "enums/colors.enum";
import { PeriodEnum } from "enums/period.enum";
import { createCollection } from "utils/createCollection";
import { SilentAlarmInterface } from "interfaces/firestore/SilentAlarmsInterface";
import { Unsubscribe, onSnapshot, query, where } from "firebase/firestore";
import { getPeriodRange } from "utils/getPeriodRange";

interface LoadingInterface {
  medical: boolean;
  aggression: boolean;
  fire: boolean;
}

export const AlarmTypesCard = () => {
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum.WEEK);
  const [medicalCount, setMedicalCount] = useState<number | null>(null);
  const [aggressionCount, setAggressionCount] = useState<number | null>(null);
  const [fireCount, setFireCount] = useState<number | null>(null);
  const tableDataRef = createCollection<SilentAlarmInterface>("alarm-messages");
  const [loading, setLoading] = useState<LoadingInterface>({
    medical: true,
    aggression: true,
    fire: true,
  });

  useEffect(() => {
    setLoading({
      medical: true,
      aggression: true,
      fire: true,
    });
    const unsubscribers: Unsubscribe[] = [];

    const getData = async () => {
      const periodRange = getPeriodRange(period);

      const dateRangeParameters = [
        where("alarm_timestamp", ">=", periodRange.from),
        where("alarm_timestamp", "<=", periodRange.to),
      ];

      const qMedical = await query(
        tableDataRef,
        ...dateRangeParameters,
        where("message", "==", "medical")
      );

      const unsubscribeMedical = onSnapshot(qMedical, async (snapshot) => {
        setMedicalCount(snapshot.size);
        setLoading((prevState) => ({ ...prevState, medical: false }));
      });

      const qAggression = await query(
        tableDataRef,
        ...dateRangeParameters,
        where("message", "==", "aggression")
      );

      const unsubscribeAggression = onSnapshot(qAggression, async (snapshot) => {
        setAggressionCount(snapshot.size);
        setLoading((prevState) => ({ ...prevState, aggression: false }));
      });

      const qFire = await query(
        tableDataRef,
        ...dateRangeParameters,
        where("message", "==", "fire")
      );

      const unsubscribeFire = onSnapshot(qFire, async (snapshot) => {
        setFireCount(snapshot.size);
        setLoading((prevState) => ({ ...prevState, fire: false }));
      });

      unsubscribers.push(unsubscribeMedical);
      unsubscribers.push(unsubscribeAggression);
      unsubscribers.push(unsubscribeFire);
    };

    getData();

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [period]);

  const handlePeriodChange = (value: PeriodEnum) => {
    setPeriod(value);
  };

  const isLoading = loading.medical || loading.aggression || loading.fire;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        py: 4,
        px: 2.5,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize="16px" fontWeight={500} color="common.black">
          Alarm Types
        </Typography>
        <PeriodSelect value={period} onChange={handlePeriodChange} />
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1.5} mb={3}>
        <LegendItem color={ColorsEnum.PRIMARY} label="Medical" />
        <LegendItem color="#97A5EB" label="Agression" />
        <LegendItem color="#FFCC91" label="Fire" />
      </Box>
      <Box display="flex" justifyContent="center" flex={1} alignItems="center">
        <DoughnutChart
          isLoading={isLoading}
          medical={medicalCount}
          aggression={aggressionCount}
          fire={fireCount}
        />
      </Box>
    </Card>
  );
};
