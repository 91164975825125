import React from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import styles from "./styles";
import { FieldEnum } from "enums/field.enum";
import { useSilentAlarmContext } from "context/SilentAlarmContext";
import { getFieldTypeLabel } from "utils/getFieldTypeLabel";

export const FieldSelect = () => {
  const { fieldType, setFieldType } = useSilentAlarmContext();

  const handleChange = (event: SelectChangeEvent) => {
    setFieldType(event.target.value as FieldEnum);
  };

  return (
    <Select
      id="field-select"
      defaultValue={FieldEnum.ID}
      size="small"
      sx={styles.select}
      value={fieldType}
      onChange={handleChange}
    >
      <MenuItem sx={styles.option} value={FieldEnum.ID}>
        {getFieldTypeLabel(FieldEnum.ID)}
      </MenuItem>
      <MenuItem sx={styles.option} value={FieldEnum.MISSION_ID}>
        {getFieldTypeLabel(FieldEnum.MISSION_ID)}
      </MenuItem>
      <MenuItem sx={styles.option} value={FieldEnum.USER_ID}>
        {getFieldTypeLabel(FieldEnum.USER_ID)}
      </MenuItem>
    </Select>
  );
};
