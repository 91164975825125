import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { TablePagination } from "components/molecules/TablePagination/TablePagination";
import { LoadableTableCell } from "components/atoms/LoadableTableCell/LoadableTableCell";
import styles from "./styles";
import { TableHeadCell } from "components/atoms/TableHeadCell/TableHeadCell";
import { useUserLogContext } from "context/UserLogContext";
import { formatDate } from "utils/formatDate";

export const UserLogTable = () => {
  const { isLoading, data, setLoadMoreFlag } = useUserLogContext();

  return (
    <TableContainer>
      <Typography sx={styles.tableHeader}>Records {data.length}</Typography>
      <Table stickyHeader sx={styles.table}>
        <TableHead>
          <TableRow sx={styles.head}>
            <TableHeadCell sx={styles.topic}>Topic</TableHeadCell>
            <TableHeadCell>Date</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data.length && !isLoading && (
            <TableRow>
              <TableCell colSpan={8} sx={styles.noResults}>
                There&apos;s nothing to show
              </TableCell>
            </TableRow>
          )}
          {!!data.length &&
            data.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "& td": {
                    border: 0,
                  },
                  "& td:last-child": {
                    pr: 0,
                  },
                  "& td:first-child": {
                    pl: 0,
                  },
                }}
              >
                <LoadableTableCell isLoading={isLoading}>{row.event_type}</LoadableTableCell>
                <LoadableTableCell isLoading={isLoading}>
                  {formatDate(row.event_epoch_stamp)}
                </LoadableTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination setLoadMoreFlag={setLoadMoreFlag} />
    </TableContainer>
  );
};
