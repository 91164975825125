import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BusyIcon = (props: SvgIconProps) => (
  <SvgIcon width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
    <path
      d="M20.0809 10.8941C20.0809 5.41405 15.6308 0.983887 10.1259 0.983887C4.62107 0.983887 0.170898 5.41405 0.170898 10.8941C0.170898 16.3742 4.64679 20.8044 10.1259 20.8044C15.605 20.8044 20.0809 16.3742 20.0809 10.8941Z"
      fill="#E73529"
    />
    <path
      d="M5.90625 6.28425C5.90625 7.02688 6.49786 7.61586 7.24385 7.61586C7.98983 7.61586 8.58145 7.02688 8.58145 6.28425C8.58145 5.54162 7.98983 4.95264 7.24385 4.95264C6.49786 4.95264 5.90625 5.54162 5.90625 6.28425Z"
      fill="white"
    />
    <path
      d="M8.99365 6.28425C8.99365 7.02688 9.58535 7.61586 10.3313 7.61586C11.0773 7.61586 11.6689 7.02688 11.6689 6.28425C11.6689 5.54162 11.0773 4.95264 10.3313 4.95264C9.58535 4.95264 8.99365 5.54162 8.99365 6.28425Z"
      fill="white"
    />
    <path
      d="M12.0811 6.28425C12.0811 7.02688 12.6727 7.61586 13.4187 7.61586C14.1646 7.61586 14.7563 7.02688 14.7563 6.28425C14.7563 5.54162 14.1646 4.95264 13.4187 4.95264C12.6727 4.95264 12.0811 5.54162 12.0811 6.28425Z"
      fill="white"
    />
    <path
      d="M8.65824 8.10254C8.32384 8.10254 8.06665 8.38423 8.06665 8.69152V12.0718C8.06665 12.4047 8.32384 12.6607 8.65824 12.6607H8.83833V16.8348H12.0024V12.6607H12.2596C12.594 12.6607 12.8512 12.4047 12.8512 12.0718V8.69152C12.8512 8.35862 12.594 8.10254 12.2596 8.10254H8.65824Z"
      fill="white"
    />
    <path
      d="M5.3927 8.10254C5.05829 8.10254 4.80103 8.38423 4.80103 8.69152V12.0718C4.80103 12.4047 5.05829 12.6607 5.3927 12.6607H5.57278V16.8348H8.01647V13.3266C7.55345 13.0961 7.2448 12.6095 7.2448 12.0718V8.69152C7.2448 8.48666 7.29621 8.28179 7.37338 8.10254H5.3927Z"
      fill="white"
    />
    <path
      d="M13.5474 8.10254C13.6246 8.28179 13.676 8.48666 13.676 8.69152V12.0718C13.676 12.6351 13.3159 13.1473 12.8271 13.3778V16.8348H15.1423V12.6607H15.3995C15.7339 12.6607 15.9911 12.4047 15.9911 12.0718V8.69152C15.9911 8.35862 15.7339 8.10254 15.3995 8.10254H13.5474Z"
      fill="white"
    />
  </SvgIcon>
);
