import React, { ReactNode } from "react";
import { Card as MuiCard, SxProps, Theme } from "@mui/material";
import styles from "./styles";
import { spreadSx } from "utils/spreadSx";

interface Props {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export const Card = ({ sx = {}, children }: Props) => (
  <MuiCard sx={spreadSx(styles.container, sx)} variant="outlined">
    {children}
  </MuiCard>
);
