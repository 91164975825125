import * as React from "react";
import { Box, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/types";
import styles from "./styles";
import { Status } from "components/atoms/Status/Status";
import { MessageStatusEnum } from "enums/status.enum";
import { formatDate } from "utils/formatDate";

interface UserBoxInfoProps {
  heading: string;
  content: string;
}

const UserBoxInfo = ({ heading, content }: UserBoxInfoProps) => (
  <Box sx={styles.info}>
    <Typography sx={styles.infoHeading}>{heading}</Typography>
    <Typography sx={styles.infoContent}>{content}</Typography>
  </Box>
);

interface Props {
  icon: OverridableComponent<any>;
  heading?: string;
  date?: number;
  status?: MessageStatusEnum;
  info: UserBoxInfoProps[];
}

export const UserBox = ({ icon: Icon, heading, date, status, info }: Props) => (
  <Box sx={styles.container}>
    <Box sx={styles.header}>
      <Box sx={styles.iconContainer}>
        <Icon sx={styles.icon} />
      </Box>
      <Box component={Box} sx={styles.dataContainer}>
        {heading && <Typography sx={styles.name}>{heading}</Typography>}
        {date && (
          <Typography sx={styles.since}>
            User since{" "}
            <Typography component="span" sx={styles.date}>
              {formatDate(date, false)}
            </Typography>
          </Typography>
        )}
      </Box>
      {status && <Status message={status} />}
    </Box>
    <Box sx={styles.infoContainer}>
      {info.map((item, i) => (
        <UserBoxInfo key={i} heading={item.heading} content={item.content} />
      ))}
    </Box>
  </Box>
);
