import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Status } from "components/atoms/Status/Status";
import { LoadableTableCell } from "components/atoms/LoadableTableCell/LoadableTableCell";
import styles from "./styles";
import { TableHeadCell } from "components/atoms/TableHeadCell/TableHeadCell";
import { Typography } from "@mui/material";
import { formatDate } from "utils/formatDate";
import { useParams } from "react-router-dom";
import { createCollection } from "utils/createCollection";
import { MessagesInterface } from "interfaces/firestore/MessagesInterface";
import { onSnapshot, query, where } from "firebase/firestore";

export const MessageInfo = () => {
  const [data, setData] = useState<MessagesInterface | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const tableDataRef = createCollection<MessagesInterface>("messages");
  const { messageId } = useParams();

  useEffect(() => {
    let unsubscribe: () => void = () => {};

    const getData = async () => {
      setIsLoading(true);
      try {
        const q = await query(tableDataRef, where("message_id", "==", messageId));
        unsubscribe = onSnapshot(q, (querySnapshot) => {
          const filteredData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
          }));
          setData(filteredData[0]);
        });
      } finally {
        setIsLoading(false);
      }
    };

    getData();

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <TableContainer>
      <Typography sx={styles.heading}>Message Details</Typography>
      <Table stickyHeader sx={styles.table}>
        <TableHead>
          <TableRow sx={styles.head}>
            <TableHeadCell>Message&nbsp;Type</TableHeadCell>
            <TableHeadCell>Message&nbsp;ID</TableHeadCell>
            <TableHeadCell>Vehicle&nbsp;ID</TableHeadCell>
            <TableHeadCell>Trips&nbsp;IDs</TableHeadCell>
            <TableHeadCell>Title</TableHeadCell>
            <TableHeadCell>Message&nbsp;Content</TableHeadCell>
            <TableHeadCell>Start</TableHeadCell>
            <TableHeadCell>End</TableHeadCell>
            <TableHeadCell>Last&nbsp;Updated</TableHeadCell>
            <TableHeadCell>Priority</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && (
            <TableRow
              sx={{
                display: "table-row",
                "& td": {
                  border: 0,
                  verticalAlign: "top",
                },
                "& td:last-child": {
                  pr: 0,
                },
                "& td:first-child": {
                  pl: 0,
                },
              }}
            >
              <LoadableTableCell isLoading={isLoading}>{data.messageType}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{data.message_id}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {data.vehicles.join(", ")}
              </LoadableTableCell>
              <LoadableTableCell
                isLoading={isLoading}
                sxCell={styles.textCell}
                sxContent={styles.tripsContent}
              >
                {data.trips.join(", ")}
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{data.title}</LoadableTableCell>
              <LoadableTableCell
                isLoading={isLoading}
                sxCell={styles.textCell}
                sxContent={styles.messageContent}
              >
                {data.content}
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {formatDate(data.startTimestamp)}
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {formatDate(data.endTimestamp)}
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {formatDate(data.lastChange)}
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{data.priority}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {<Status message={data.status} />}
              </LoadableTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
