import React, { ReactNode } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { MenuDrawer } from "components/organisms/MenuDrawer/MenuDrawer";
import { Navbar } from "components/organisms/Navbar/Navbar";
import { Sidebar } from "components/organisms/Sidebar/Sidebar";
import { GesamtTable } from "components/organisms/GesamtTable/GesamtTable";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}

export const ContentTemplate = ({ children }: Props) => {
  const theme = useTheme();
  const showSidebar = useMediaQuery(theme.breakpoints.up("md"));
  const { pathname } = useLocation();

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Navbar />
      <Box
        flex={1}
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "100vw",
            md: "175px calc(100vw - 175px)",
          },
        }}
      >
        {showSidebar && <Sidebar />}
        {!showSidebar && <MenuDrawer />}
        <Box flex={1} display="flex" flexDirection="column" height="calc(100vh - 64px)">
          <GesamtTable />
          <Box
            px={{
              xs: 2,
              md: 4,
            }}
            py={4}
            sx={{ overflowY: "auto", backgroundColor: pathname === "/" ? "#F1F1F1" : "white" }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
