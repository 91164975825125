import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    p: 1,
    display: "flex",
    fontSize: 20,
    fontWeight: 500,
  },
  title: {
    mr: 1,
    fontSize: "inherit",
    fontWeight: "inherit",
  },
  content: {
    color: ColorsEnum.TEXT_SECONDARY,
    fontSize: "inherit",
    fontWeight: "inherit",
  },
};

export default styles;
