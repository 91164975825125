import { Card } from "components/atoms/Card/Card";
import React from "react";
import { BarChart } from "components/molecules/BarChart/BarChart";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PeriodSelect } from "components/molecules/PeriodSelect/PeriodSelect";
import { CategorySelect } from "components/molecules/CategorySelect/CategorySelect";
import { PeriodEnum } from "enums/period.enum";
import { ColorsEnum } from "enums/colors.enum";

export const SummaryCard = () => {
  return (
    <Card
      sx={{
        py: 2,
        pb: 1.5,
        px: 2.5,
        background: "#ddd",
      }}
    >
      <Box display="flex" justifyContent="space-between" pb={4}>
        <Box display="flex" alignItems="center">
          <Typography color={ColorsEnum.HEADING} fontSize={16} fontWeight={500} mr={2.5}>
            Summary
          </Typography>
          <CategorySelect />
        </Box>
        <PeriodSelect value={PeriodEnum.WEEK} onChange={() => {}} color="common.black" />
      </Box>
      <Box height="calc(100% - 65px)">
        <BarChart />
      </Box>
    </Card>
  );
};
