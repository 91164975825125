import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Status } from "components/atoms/Status/Status";
import { TablePagination } from "components/molecules/TablePagination/TablePagination";
import { TableHeader } from "components/molecules/TableHeader/TableHeader";
import { LoadableTableCell } from "components/atoms/LoadableTableCell/LoadableTableCell";
import { useSilentAlarmContext } from "context/SilentAlarmContext";
import styles from "./styles";
import { DateRangeModal } from "../Modals/DateRangeModal/DateRangeModal";
import { formatDate } from "utils/formatDate";
import { TableHeadCell } from "components/atoms/TableHeadCell/TableHeadCell";
import { TableCell } from "@mui/material";

export const AlarmTable = () => {
  const {
    search,
    setSearch,
    isDateRandeModalOpen,
    setIsDateRandeModalOpen,
    dateRange,
    setDateRange,
    fieldType,
    isLoading,
    data,
    setLoadMoreFlag,
  } = useSilentAlarmContext();

  return (
    <TableContainer>
      <TableHeader
        search={search}
        setSearch={setSearch}
        setIsDateRandeModalOpen={setIsDateRandeModalOpen}
        dateRange={dateRange}
        setDateRange={setDateRange}
        fieldType={fieldType}
        heading="Silent Alarm Log"
      />
      <Table stickyHeader sx={styles.table}>
        <TableHead>
          <TableRow sx={styles.head}>
            <TableHeadCell>Alarm&nbsp;ID</TableHeadCell>
            <TableHeadCell>Alarm&nbsp;Type</TableHeadCell>
            <TableHeadCell>Route</TableHeadCell>
            <TableHeadCell>Mission&nbsp;ID</TableHeadCell>
            <TableHeadCell>Carriage&nbsp;#</TableHeadCell>
            <TableHeadCell>Deck</TableHeadCell>
            <TableHeadCell>Timestamp</TableHeadCell>
            <TableHeadCell>User&nbsp;ID</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data.length && !isLoading && (
            <TableRow>
              <TableCell colSpan={9} sx={styles.noResults}>
                There&apos;s nothing to show
              </TableCell>
            </TableRow>
          )}
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                "& td": {
                  border: 0,
                },
                "& td:last-child": {
                  pr: 0,
                },
                "& td:first-child": {
                  pl: 0,
                },
              }}
            >
              <LoadableTableCell isLoading={isLoading}>{row.id}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{row.message}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{row.route}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{row.mission_id}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{row.carriage_id}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{row.deck}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {formatDate(row.alarm_timestamp)}
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{row.user_id}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {<Status message={row.status} />}
              </LoadableTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination setLoadMoreFlag={setLoadMoreFlag} />
      <DateRangeModal
        isDateRandeModalOpen={isDateRandeModalOpen}
        setIsDateRandeModalOpen={setIsDateRandeModalOpen}
        setDateRange={setDateRange}
      />
    </TableContainer>
  );
};
