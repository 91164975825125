import { FieldEnum } from "enums/field.enum";

export const getFieldTypeLabel = (type: FieldEnum) => {
  if (type === FieldEnum.ID) {
    return "Alarm ID";
  }
  if (type === FieldEnum.MISSION_ID) {
    return "Mission ID";
  }
  return "User ID";
};
