import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./styles";
import { numberWithCommas } from "utils/numberWithCommas";

interface Props {
  category?: string;
  header: string;
  content: number;
}

export const GesamtTableItem = ({ category, header, content }: Props) => (
  <Box sx={styles.container}>
    {category && <Typography sx={styles.category}>{category}</Typography>}
    <Typography sx={styles.header}>{header}</Typography>
    <Typography sx={styles.content}>{numberWithCommas(content)}</Typography>
  </Box>
);
