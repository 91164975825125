import React from "react";
import { useAuthContext } from "context/AuthContext";
import { Navigate } from "react-router-dom";

interface Props {
  children: JSX.Element;
}

export const ProtectedRoute = ({ children }: Props) => {
  const { isChecking, isAuthorized } = useAuthContext();
  if (isChecking) return null;

  if (isAuthorized) {
    return children;
  }
  return <Navigate to="/login" replace />;
};
