import React, { ReactNode } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import styles from "./styles";

export const TableHeadCell = ({ children, sx }: { children: ReactNode; sx?: SxProps<Theme> }) => (
  <TableCell align="left" sx={sx}>
    <Box sx={styles.headContent}>{children}</Box>
  </TableCell>
);
