import React from "react";
import { Button, Stack, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./styles";
import { DateRangeInterface } from "interfaces/DateRangeInterface";

interface FormValues {
  from: Date | Dayjs;
  to: Date | Dayjs;
}

const schema = yup.object().shape({
  from: yup
    .date()
    .typeError("Invalid Date")
    .max(dayjs().endOf("day"), "Date can't be future")
    .required(),
  to: yup
    .date()
    .typeError("Invalid Date")
    .min(yup.ref("from"), "End date can't be before start date")
    .max(dayjs().endOf("day"), "Date can't be future")
    .required(),
});

interface Props {
  isDateRandeModalOpen: boolean;
  setIsDateRandeModalOpen: (isDateRandeModalOpen: boolean) => void;
  setDateRange: (range: DateRangeInterface) => void;
}

export const DateRangeModal = ({
  isDateRandeModalOpen,
  setIsDateRandeModalOpen,
  setDateRange,
}: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      from: dayjs().subtract(1, "week"),
      to: dayjs(),
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setIsDateRandeModalOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    if ("getTime" in data.from && "getTime" in data.to) {
      setDateRange({
        from: dayjs(data.from).startOf("d").valueOf(),
        to: dayjs(data.to).endOf("d").valueOf(),
      });
    }
    setIsDateRandeModalOpen(false);
  });

  return (
    <Dialog
      open={isDateRandeModalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Select Date Range</DialogTitle>
      <DialogContent>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 4, md: 2 }} sx={{ pt: 2 }}>
          <Controller
            name="from"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="From"
                format="DD/MM/YYYY"
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    ...(errors.from?.message && {
                      helperText: errors.from.message,
                      sx: styles.error,
                    }),
                  },
                }}
                {...field}
              />
            )}
          />
          <Controller
            name="to"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="To"
                format="DD/MM/YYYY"
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    ...(errors.to?.message && { helperText: errors.to.message, sx: styles.error }),
                  },
                }}
                {...field}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button autoFocus onClick={onSubmit} disabled={!!errors.from || !!errors.to}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
