import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const UsersIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99293 12.6724C11.0671 12.6724 13.6946 13.1382 13.6946 14.999C13.6946 16.8599 11.0846 17.339 7.99293 17.339C4.91793 17.339 2.29126 16.8774 2.29126 15.0157C2.29126 13.154 4.90043 12.6724 7.99293 12.6724Z"
      stroke="#1C1D22"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99289 10.0163C5.97456 10.0163 4.33789 8.38051 4.33789 6.36217C4.33789 4.34384 5.97456 2.70801 7.99289 2.70801C10.0104 2.70801 11.6471 4.34384 11.6471 6.36217C11.6546 8.37301 10.0296 10.0088 8.01872 10.0163H7.99289Z"
      stroke="#1C1D22"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.736 9.0679C15.0701 8.8804 16.0976 7.7354 16.1001 6.34956C16.1001 4.98373 15.1043 3.8504 13.7985 3.63623"
      stroke="#1C1D22"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4962 12.2769C16.7887 12.4694 17.6912 12.9227 17.6912 13.856C17.6912 14.4985 17.2662 14.9152 16.5795 15.176"
      stroke="#1C1D22"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
