/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Box from "@mui/material/Box";
import { ColorsEnum } from "enums/colors.enum";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        color: "#A6A8B1",
        padding: 20,
      },
    },
    y: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        color: "#A6A8B1",
        callback: (value: string | number) => {
          return `${(value as number) / 1000}k`;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      filter: (tooltipItem) => tooltipItem.datasetIndex == 0,
      animation: false,
      mode: "index",
      intersect: true,
      yAlign: "bottom",
    },
  },
};

const labels = ["Sept 11", "Sept 12", "Sept 13", "Sept 14", "Sept 15", "Sept 16", "Sept 17"];

const data = {
  labels,
  datasets: [
    {
      barPercentage: 0.5,
      barThickness: 15,
      minBarLength: 2,
      data: [90000, 40000, 70000, 20000, 80000, 50000, 80000],
      backgroundColor: ColorsEnum.PRIMARY,
      borderRadius: Number.MAX_VALUE,
      borderSkipped: false,
    },
    {
      barPercentage: 0.5,
      barThickness: 15,
      minBarLength: 2,
      data: [100000, 100000, 100000, 100000, 100000, 100000, 100000],
      backgroundColor: "#EEF0FA",
      hoverBackgroundColor: "#EEF0FA",
      borderRadius: Number.MAX_VALUE,
      borderSkipped: false,
    },
  ],
};

export const BarChart = () => {
  return (
    <Box
      component={Bar}
      id="summary"
      data={data}
      options={options}
      sx={{
        width: "100% !important",
        height: "unset !important",
      }}
    />
  );
};
