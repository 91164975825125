import React from "react";
import { Box } from "@mui/material";
import { Card } from "components/atoms/Card/Card";
import { PeriodSelect } from "components/molecules/PeriodSelect/PeriodSelect";
import { IconBadge } from "components/atoms/IconBadge/IconBadge";
import { GraphIcon } from "assets/icons/GraphIcon";
import { CardRecord } from "components/molecules/CardRecord/CardRecord";
import { ColorsEnum } from "enums/colors.enum";
import { PeriodEnum } from "enums/period.enum";

export const PassengersCard = () => (
  <Card
    sx={{
      background: "#ddd",
    }}
  >
    <Box display="flex" justifyContent="space-between">
      <IconBadge
        Icon={GraphIcon}
        stroke={ColorsEnum.PRIMARY}
        backgroundColor={`${ColorsEnum.PRIMARY}1F`}
      />
      <PeriodSelect value={PeriodEnum.WEEK} onChange={() => {}} />
    </Box>
    <Box display="flex">
      <CardRecord heading="# of Passenger" content="8,301,999" />
      <CardRecord heading="Volume" content="450" difference="--.--" />
    </Box>
  </Card>
);
