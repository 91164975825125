import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Card } from "components/atoms/Card/Card";
import { PeriodSelect } from "components/molecules/PeriodSelect/PeriodSelect";
import { IconBadge } from "components/atoms/IconBadge/IconBadge";
import { CardRecord } from "components/molecules/CardRecord/CardRecord";
import { AlarmIcon } from "assets/icons/AlarmIcon";
import { ColorsEnum } from "enums/colors.enum";
import { PeriodEnum } from "enums/period.enum";
import { Unsubscribe, onSnapshot, query, where } from "firebase/firestore";
import { getPeriodRange } from "utils/getPeriodRange";
import { createCollection } from "utils/createCollection";
import { SilentAlarmInterface } from "interfaces/firestore/SilentAlarmsInterface";

interface LoadingInterface {
  all: boolean;
  pending: boolean;
  received: boolean;
}

export const AlarmsCard = () => {
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum.WEEK);
  const [allAlarmsCount, setAllAlarmsCount] = useState(0);
  const [pendingAlarmsCount, setPendingAlarmsCount] = useState(0);
  const [receivedAlarmsCount, setReceivedAlarmsCount] = useState(0);
  const tableDataRef = createCollection<SilentAlarmInterface>("alarm-messages");
  const [loading, setLoading] = useState<LoadingInterface>({
    all: true,
    pending: true,
    received: true,
  });

  useEffect(() => {
    setLoading({
      all: true,
      pending: true,
      received: true,
    });
    const unsubscribers: Unsubscribe[] = [];

    const getData = async () => {
      const periodRange = getPeriodRange(period);

      const dateRangeParameters = [
        where("alarm_timestamp", ">=", periodRange.from),
        where("alarm_timestamp", "<=", periodRange.to),
      ];

      const qAll = await query(tableDataRef, ...dateRangeParameters);

      const unsubscribeAll = onSnapshot(qAll, async (snapshot) => {
        setAllAlarmsCount(snapshot.size);
        setLoading((prevState) => ({ ...prevState, all: false }));
      });

      const qPending = await query(
        tableDataRef,
        ...dateRangeParameters,
        where("status", "==", "pending")
      );

      const unsubscribePending = onSnapshot(qPending, async (snapshot) => {
        setPendingAlarmsCount(snapshot.size || 0);
        setLoading((prevState) => ({ ...prevState, pending: false }));
      });

      const qReceived = await query(
        tableDataRef,
        ...dateRangeParameters,
        where("status", "==", "received")
      );

      const unsubscribeReceived = onSnapshot(qReceived, async (snapshot) => {
        setReceivedAlarmsCount(snapshot.size || 0);
        setLoading((prevState) => ({ ...prevState, received: false }));
      });

      unsubscribers.push(unsubscribeAll);
      unsubscribers.push(unsubscribePending);
      unsubscribers.push(unsubscribeReceived);
    };

    getData();

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [period]);

  const handlePeriodChange = (value: PeriodEnum) => {
    setPeriod(value);
  };

  const isLoading = loading.all || loading.pending;

  return (
    <Card sx={{ backgroundColor: ColorsEnum.SECONDARY }}>
      <Box display="flex" justifyContent="space-between">
        <IconBadge
          Icon={AlarmIcon}
          stroke="common.black"
          backgroundColor={ColorsEnum.WARNING_FADED}
        />
        <PeriodSelect value={period} onChange={handlePeriodChange} color="common.white" />
      </Box>
      <Box display="flex">
        <CardRecord heading="All Alarms" content={allAlarmsCount} white isLoading={isLoading} />
        <CardRecord
          heading="Pending"
          content={pendingAlarmsCount}
          white
          warning={!!pendingAlarmsCount}
          isLoading={isLoading}
        />
        <CardRecord heading="Received" content={receivedAlarmsCount} white isLoading={isLoading} />
        <CardRecord
          heading="Acknowledged"
          content={allAlarmsCount - pendingAlarmsCount - receivedAlarmsCount}
          white
          isLoading={isLoading}
        />
      </Box>
    </Card>
  );
};
