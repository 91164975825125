/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box, CircularProgress } from "@mui/material";
import { ColorsEnum } from "enums/colors.enum";

ChartJS.register(ArcElement, Tooltip, Legend);

const options: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      yAlign: "bottom",
      filter: (tooltipItem) => tooltipItem.datasetIndex == 1,
      callbacks: {
        label: function (context) {
          return `  ${context.label}: ${context.formattedValue}`;
        },
        title: () => "",
      },
      animation: false,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
};

interface Props {
  medical: number | null;
  aggression: number | null;
  fire: number | null;
  isLoading: boolean;
}

export const DoughnutChart = ({ medical, aggression, fire, isLoading }: Props) => {
  if (isLoading)
    return (
      <CircularProgress
        color="primary"
        sx={{ width: "60px !important", height: "60px !important" }}
      />
    );

  const data = useMemo(
    () => ({
      labels: ["Medical", "Agression", "Fire"],
      datasets: [
        {
          data: [1],
          backgroundColor: "#EEF0FA",
          hoverBackgroundColor: "#EEF0FA",
          borderWidth: 0,
          weight: 0.9,
        },
        {
          data: [medical, aggression, fire],
          backgroundColor: [ColorsEnum.PRIMARY, "#97A5EB", "#FFCC91"],
          borderWidth: 0,
        },
        {
          data: [1],
          backgroundColor: "#EEF0FA",
          hoverBackgroundColor: "#EEF0FA",
          borderWidth: 0,
          weight: 0.9,
        },
      ],
    }),
    [medical, aggression, fire]
  );

  return (
    <Box
      component={Doughnut}
      data={data}
      options={options}
      sx={{
        width: "100% !important",
        height: "unset !important",
        maxWidth: "205px",
        maxHeight: "205px",
      }}
    />
  );
};
