import React, { ReactNode } from "react";
import { Box, Skeleton, TableCell } from "@mui/material";
import styles from "./styles";
import { spreadSx } from "utils/spreadSx";
import { SxProps, Theme } from "@mui/material/styles";

interface LoadableTableCellProps {
  children: ReactNode;
  isLoading: boolean;
  sxCell?: SxProps<Theme>;
  sxContent?: SxProps<Theme>;
}

export const LoadableTableCell = ({
  children,
  isLoading,
  sxCell = {},
  sxContent = {},
}: LoadableTableCellProps) => (
  <TableCell align="left" sx={spreadSx(styles.cell, sxCell)}>
    <Box sx={spreadSx(styles.content, sxContent)}>
      <Box sx={isLoading ? styles.hidden : {}}>{children}</Box>
      {isLoading && <Skeleton sx={styles.skeleton} variant="text" />}
    </Box>
  </TableCell>
);
