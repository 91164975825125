import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FilterIcon = (props: SvgIconProps) => (
  <SvgIcon width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
    <path
      d="M14.9995 2.38977H1.83911L7.10329 8.61466V12.9181L9.73537 14.2342V8.61466L14.9995 2.38977Z"
      stroke="#53545C"
      strokeWidth="1.48055"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
