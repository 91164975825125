import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Link as MuiLink,
} from "@mui/material";
import { Status } from "components/atoms/Status/Status";
import { TablePagination } from "components/molecules/TablePagination/TablePagination";
import { TableHeader } from "components/molecules/TableHeader/TableHeader";
import { LoadableTableCell } from "components/atoms/LoadableTableCell/LoadableTableCell";
import styles from "./styles";
import { DateRangeModal } from "../Modals/DateRangeModal/DateRangeModal";
import { TableHeadCell } from "components/atoms/TableHeadCell/TableHeadCell";
import { useUsersContext } from "context/UsersContext";
import { formatDate } from "utils/formatDate";
import { Link } from "react-router-dom";
import { RoutesEnum } from "enums/routes.enum";

export const UsersTable = () => {
  const {
    search,
    setSearch,
    isDateRandeModalOpen,
    setIsDateRandeModalOpen,
    dateRange,
    setDateRange,
    isLoading,
    data,
    setLoadMoreFlag,
    selected,
    handleSelect,
  } = useUsersContext();

  return (
    <TableContainer>
      <TableHeader
        search={search}
        setSearch={setSearch}
        setIsDateRandeModalOpen={setIsDateRandeModalOpen}
        dateRange={dateRange}
        setDateRange={setDateRange}
        heading="Search User Records"
        searchType="User ID"
        bulkActions={[
          {
            label: "Delete Selected",
            onClick: () => {},
          },
          {
            label: "Delete All",
            onClick: () => {},
          },
        ]}
      />
      <Table stickyHeader sx={styles.table}>
        <TableHead>
          <TableRow sx={styles.head}>
            <TableHeadCell>&nbsp;</TableHeadCell>
            <TableHeadCell>Full&nbsp;Name</TableHeadCell>
            <TableHeadCell>Creation&nbsp;Date</TableHeadCell>
            <TableHeadCell>Role</TableHeadCell>
            <TableHeadCell>User&nbsp;ID</TableHeadCell>
            <TableHeadCell>Email</TableHeadCell>
            <TableHeadCell>Phone</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data.length && !isLoading && (
            <TableRow>
              <TableCell colSpan={8} sx={styles.noResults}>
                There&apos;s nothing to show
              </TableCell>
            </TableRow>
          )}
          {!!data.length &&
            data.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "& td": {
                    border: 0,
                  },
                  "& td:last-child": {
                    pr: 0,
                  },
                  "& td:first-child": {
                    pl: 0,
                  },
                }}
              >
                <TableCell sx={{ py: 0, width: 50 }}>
                  <Checkbox
                    checked={!!selected.some((id) => id === row.id)}
                    onClick={(ev) => {
                      ev.stopPropagation();
                    }}
                    onChange={() => {
                      handleSelect(row.id);
                    }}
                  />
                </TableCell>
                <LoadableTableCell isLoading={isLoading}>{row.display_name}</LoadableTableCell>
                <LoadableTableCell isLoading={isLoading}>
                  {formatDate(row.registration_timestamp)}
                </LoadableTableCell>
                <LoadableTableCell isLoading={isLoading}>{row.role}</LoadableTableCell>
                <LoadableTableCell isLoading={isLoading}>
                  <MuiLink component={Link} to={`${RoutesEnum.USERS}/${row.id}`}>
                    {row.userid}
                  </MuiLink>
                </LoadableTableCell>
                <LoadableTableCell isLoading={isLoading}>{row.email}</LoadableTableCell>
                <LoadableTableCell isLoading={isLoading}>{row.phone_number}</LoadableTableCell>
                <LoadableTableCell isLoading={isLoading}>
                  {<Status message={row.status} />}
                </LoadableTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination setLoadMoreFlag={setLoadMoreFlag} />
      <DateRangeModal
        isDateRandeModalOpen={isDateRandeModalOpen}
        setIsDateRandeModalOpen={setIsDateRandeModalOpen}
        setDateRange={setDateRange}
      />
    </TableContainer>
  );
};
