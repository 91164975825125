import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "views/Home/Home";
import { RoutesEnum } from "enums/routes.enum";
import { SilentAlarm } from "views/SilentAlarm/SilentAlarm";
import { Users } from "views/Users/Users";
import { UserLog } from "views/UserLog/UserLog";
import { Messages } from "views/Messages/Messages";
import { MessageInfo } from "views/MessageInfo/MessageInfo";
import { MessagesContextProvider } from "context/MessagesContext";
import { UsersContextProvider } from "context/UsersContext";
import { CreateMessage } from "views/CreateMessage/CreateMessage";

export const MainRoutes = () => (
  <>
    <Routes>
      <Route path={RoutesEnum.HOME} element={<Home />} />
      <Route path={RoutesEnum.SILENT_ALARM} element={<SilentAlarm />} />
      <Route element={<UsersContextProvider />}>
        <Route path={RoutesEnum.USERS} element={<Users />} />
        <Route path={RoutesEnum.USER_LOG} element={<UserLog />} />
      </Route>
      <Route element={<MessagesContextProvider />}>
        <Route path={RoutesEnum.MESSAGES} element={<Messages />} />
        <Route path={RoutesEnum.MESSAGE} element={<MessageInfo />} />
      </Route>
      <Route path={RoutesEnum.CREATE_MESSAGE} element={<CreateMessage />} />
    </Routes>
  </>
);
