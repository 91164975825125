import React from "react";
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  ButtonBase,
  IconButton,
  TableCell,
} from "@mui/material";
import { Status } from "components/atoms/Status/Status";
import { TablePagination } from "components/molecules/TablePagination/TablePagination";
import { TableHeader } from "components/molecules/TableHeader/TableHeader";
import { LoadableTableCell } from "components/atoms/LoadableTableCell/LoadableTableCell";
import styles from "./styles";
import { DateRangeModal } from "../Modals/DateRangeModal/DateRangeModal";
import { TableHeadCell } from "components/atoms/TableHeadCell/TableHeadCell";
import { useMessagesContext } from "context/MessagesContext";
import { TextClamp } from "components/atoms/TextClamp/TextClamp";
import { useNavigate } from "react-router-dom";
import { RoutesEnum } from "enums/routes.enum";
import { formatDate } from "utils/formatDate";
import SouthIcon from "@mui/icons-material/South";
import { OrderEnum } from "enums/order.enum";
import { MessageFilter } from "../MessageFilter/MessageFilter";
import AddIcon from "@mui/icons-material/Add";

export const MessagesTable = () => {
  const {
    search,
    setSearch,
    isDateRandeModalOpen,
    setIsDateRandeModalOpen,
    dateRange,
    setDateRange,
    isLoading,
    data,
    setLoadMoreFlag,
    order,
    setOrder,
    setIsFilterModalOpen,
    setFilterOptions,
    isFilterModalOpen,
    filterOptions,
  } = useMessagesContext();
  const navigate = useNavigate();

  const handleClick = (messageId: string) => {
    navigate(`${RoutesEnum.MESSAGES}/${messageId}`);
  };

  const handleOrderClick = () => {
    if (order === OrderEnum.DESC) {
      setOrder(OrderEnum.ACS);
    } else {
      setOrder(OrderEnum.DESC);
    }
  };

  return (
    <TableContainer>
      <TableHeader
        search={search}
        setSearch={setSearch}
        setIsDateRandeModalOpen={setIsDateRandeModalOpen}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setIsFilterModalOpen={setIsFilterModalOpen}
        heading="Messages Log"
        searchType="Message ID"
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        customComponent={
          <Button
            sx={styles.addButton}
            startIcon={<AddIcon />}
            size="small"
            color="inherit"
            variant="outlined"
            onClick={() => {
              navigate(RoutesEnum.CREATE_MESSAGE);
            }}
          >
            Create Message
          </Button>
        }
      />
      <Table stickyHeader sx={styles.table}>
        <TableHead>
          <TableRow sx={styles.head}>
            <TableHeadCell>Message&nbsp;Type</TableHeadCell>
            <TableHeadCell>Message&nbsp;ID</TableHeadCell>
            <TableHeadCell>Vehicle&nbsp;ID</TableHeadCell>
            <TableHeadCell>Trips&nbsp;IDs</TableHeadCell>
            <TableHeadCell>Title</TableHeadCell>
            <TableHeadCell>Message&nbsp;Content</TableHeadCell>
            <TableHeadCell>Start</TableHeadCell>
            <TableHeadCell>End</TableHeadCell>
            <TableHeadCell>
              Last&nbsp;Updated{" "}
              <IconButton onClick={handleOrderClick} aria-label="change order">
                <SouthIcon
                  fontSize="small"
                  sx={order === OrderEnum.ACS ? styles.sortArrowUp : {}}
                />
              </IconButton>
            </TableHeadCell>
            <TableHeadCell>Priority</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data.length && !isLoading && (
            <TableRow>
              <TableCell colSpan={11} sx={styles.noResults}>
                There&apos;s nothing to show
              </TableCell>
            </TableRow>
          )}
          {data.map((row) => (
            <ButtonBase
              component={TableRow}
              key={row.message_id}
              sx={{
                display: "table-row",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.05)",
                },
                "& td": {
                  border: 0,
                },
                "& td:last-child": {
                  pr: 0,
                },
                "& td:first-child": {
                  pl: 0,
                },
              }}
              onClick={() => handleClick(row.message_id)}
            >
              <LoadableTableCell isLoading={isLoading}>{row.messageType}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{row.message_id}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                <TextClamp lines={2}>{row.vehicles.join(", ")}</TextClamp>
              </LoadableTableCell>
              <LoadableTableCell
                isLoading={isLoading}
                sxCell={styles.textCell}
                sxContent={styles.tripsContent}
              >
                <TextClamp lines={2}>{row.trips.join(", ")}</TextClamp>
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                <TextClamp lines={2}>{row.title}</TextClamp>
              </LoadableTableCell>
              <LoadableTableCell
                isLoading={isLoading}
                sxCell={styles.textCell}
                sxContent={styles.messageContent}
              >
                <TextClamp lines={2}>{row.content}</TextClamp>
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {formatDate(row.startTimestamp)}
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {formatDate(row.endTimestamp)}
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {formatDate(row.lastChange)}
              </LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>{row.priority}</LoadableTableCell>
              <LoadableTableCell isLoading={isLoading}>
                {<Status message={row.status} />}
              </LoadableTableCell>
            </ButtonBase>
          ))}
        </TableBody>
      </Table>
      <TablePagination setLoadMoreFlag={setLoadMoreFlag} />
      <DateRangeModal
        isDateRandeModalOpen={isDateRandeModalOpen}
        setIsDateRandeModalOpen={setIsDateRandeModalOpen}
        setDateRange={setDateRange}
      />
      {isFilterModalOpen && <MessageFilter />}
    </TableContainer>
  );
};
