import { SxProps, Theme } from "@mui/material/styles";

export const spreadSx = (...sx: (SxProps<Theme> | false)[]): SxProps<Theme> => {
  const arr: SxProps<Theme> = [];

  sx.forEach((styles) => {
    if (Array.isArray(styles)) {
      styles.forEach((style) => (arr as SxProps<Theme>[]).push(style));
    } else if (styles) {
      (arr as SxProps<Theme>[]).push(styles);
    }
  });
  return arr;
};
