import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    display: "flex",
    flexDirection: {
      xs: "column",
      xl: "row",
    },
    gap: {
      xs: 1,
      xl: 0,
    },
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    left: 0,
    flexWrap: "wrap",
  },
  heading: {
    mb: {
      xs: 2,
      sm: 0,
    },
    fontWeight: 500,
    fontSize: 16,
    color: ColorsEnum.HEADING,
  },
  chipContainer: {
    px: 2,
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    flexWrap: "wrap",
  },
  stack: { color: "#53545C" },
  search: {
    maxWidth: 180,
    "& .MuiInputBase-root": {
      pl: 1,
    },
    "& input": {
      py: 1,
      fontSize: 12,
      lineHeight: "14px",
    },
  },
  divider: {
    ml: "16px !important",
    mr: "8px !important",
  },
  actionButton: {
    fontSize: 12,
    textTransform: "unset",
  },
};

export default styles;
