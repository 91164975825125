import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  wrapper: {
    width: "100%",
    py: 0.75,
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
    borderTop: "solid 1px #d5d5d5",
    position: "sticky",
    left: 0,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: {
      xs: "100%",
      sm: 1,
    },
  },
  items: {
    fontSize: 14,
    fontWeight: 400,
    color: "#666666",
  },
  navigation: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    py: 0.5,
    borderRadius: 2,
    fontSize: 12,
    color: ColorsEnum.TEXT_SECONDARY,
    background: "rgba(94, 99, 102, 0.08)",
    "&.Mui-focused": {
      background: "rgba(94, 99, 102, 0.16)",
    },
    "& .MuiSelect-select": {
      py: 0,
      background: "transparent !important",
    },
    "&::before, &::after": {
      content: "unset",
    },
  },
  menu: {
    maxHeight: 200,
  },
  pages: {
    ml: 1,
    fontSize: 14,
    fontWeight: 400,
    color: "#666666",
  },
  buttonContainer: {
    ml: 3,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    "&[disabled] svg": {
      color: "#66666666",
    },
  },
  icon: {
    width: 20,
    height: 20,
    color: "#666666",
  },
  skeleton: {
    width: 130,
    height: "100%",
    display: "inline-block",
    transform: "none",
  },
};
export default styles;
