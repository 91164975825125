import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PeriodEnum } from "enums/period.enum";

const menuItems = [PeriodEnum.TODAY, PeriodEnum.WEEK, PeriodEnum.MONTH];

interface Props {
  color?: string;
  value?: PeriodEnum;
  onChange: (index: PeriodEnum) => void;
}

export const PeriodSelect = ({ color = "#BEC0CA", value = PeriodEnum.WEEK, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: PeriodEnum) => {
    onChange(index);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={{ textTransform: "unset", color, fontWeight: 400 }}
        size="small"
        variant="text"
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              rotate: open ? "180deg" : 0,
            }}
          />
        }
        onClick={handleClick}
      >
        {value}
      </Button>
      <Menu id="period-menu" anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ color }}>
        {menuItems.map((item) => (
          <MenuItem dense key={item} onClick={(event) => handleMenuItemClick(event, item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
