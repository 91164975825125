import React from "react";
import { Box, Typography } from "@mui/material";
import { ModerateIcon } from "assets/icons/ModerateIcon";
import styles from "./styles";
import { BusyIcon } from "assets/icons/BusyIcon";
import { ArrowUpIcon } from "assets/icons/ArrowUp";

export const GesamtTableTrend = () => (
  <Box sx={styles.container}>
    <Typography sx={styles.heading}>Trend</Typography>
    <ModerateIcon />
    <Typography sx={styles.text}>105,0%</Typography>
    <Box sx={styles.trendContainer}>
      <ArrowUpIcon sx={styles.arrow} />
      <Typography sx={styles.trend}>+5,0%</Typography>
    </Box>
    <Typography sx={styles.text}>2022</Typography>
    <BusyIcon />
    <Typography sx={styles.text}>120,0%</Typography>
    <Box sx={styles.trendContainer}>
      <ArrowUpIcon sx={styles.arrow} />
      <Typography sx={styles.trend}>+15,0%</Typography>
    </Box>
    <Typography sx={styles.text}>2023</Typography>
  </Box>
);
