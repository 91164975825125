import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MainRoutes } from "routes";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "theme";
import { GlobalStyle } from "theme/GlobalStyle";
import { ContentTemplate } from "./ContentTemplate";
import { AuthContextProvider } from "context/AuthContext";
import { RoutesEnum } from "enums/routes.enum";
import { Login } from "views/Login/Login";
import { Forbidden } from "views/Forbidden/Forbidden";
import { ProtectedRoute } from "routes/ProtectedRoute";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const RootTemplate = () => (
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        <AuthContextProvider>
          <Routes>
            <Route path={RoutesEnum.LOGIN} element={<Login />} />
            <Route path={RoutesEnum.FORBIDDEN} element={<Forbidden />} />
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <ContentTemplate>
                    <MainRoutes />
                  </ContentTemplate>
                </ProtectedRoute>
              }
            />
          </Routes>
        </AuthContextProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </BrowserRouter>
);
