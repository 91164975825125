import { useEffect, useRef, EffectCallback, DependencyList } from "react";

export const useDidUpdate = (fn: EffectCallback, dependencies?: DependencyList) => {
  const mounted = useRef(false);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  useEffect(() => {
    if (mounted.current) {
      return fn();
    }

    mounted.current = true;
    return undefined;
  }, dependencies);
};
