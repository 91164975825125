import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import styles from "./styles";

interface Props {
  setLoadMoreFlag: (loadMore: boolean) => void;
}

export const TablePagination = ({ setLoadMoreFlag }: Props) => {
  const itemsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      entry.isIntersecting && setLoadMoreFlag(true);
    });
    itemsRef.current && observer.observe(itemsRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container} ref={itemsRef}></Box>
    </Box>
  );
};
