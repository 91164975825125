import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    "& .MuiPaper-root": {
      maxWidth: 450,
      width: "100%",
    },
  },
  content: {
    px: 3,
  },
  types: {
    mb: 1.5,
    display: "flex",
    justifyContent: "space-between",
  },
  buttonsContainer: {
    color: ColorsEnum.LINK,
  },
  actionButtonsContainer: {
    my: 3,
    display: "flex",
    justifyContent: "space-around",
    color: ColorsEnum.NAV,
  },
  apply: {
    backgroundColor: ColorsEnum.NAV,
    color: "common.white",
    "&:hover": {
      backgroundColor: ColorsEnum.NAV,
      opacity: 0.9,
    },
  },
};
export default styles;
