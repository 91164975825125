import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { ColorsEnum } from "enums/colors.enum";

export const GraphIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3982 11.5745C14.9605 11.5745 15.4324 12.0386 15.3465 12.5938C14.8421 15.8605 12.0456 18.2859 8.67277 18.2859C4.94119 18.2859 1.91663 15.2614 1.91663 11.5307C1.91663 8.45699 4.25171 5.59383 6.88066 4.94646C7.44557 4.80699 8.02452 5.20436 8.02452 5.78593C8.02452 9.72629 8.15698 10.7456 8.90522 11.3C9.65347 11.8544 10.5333 11.5745 14.3982 11.5745Z"
      stroke={ColorsEnum.PRIMARY}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.077 8.293C18.1218 5.76142 15.0121 1.68072 11.2226 1.75089C10.9279 1.75616 10.6919 2.00177 10.6788 2.29563C10.5832 4.37721 10.7121 7.07458 10.784 8.29738C10.806 8.67809 11.1051 8.97721 11.4849 8.99914C12.7419 9.07107 15.5376 9.16931 17.5893 8.85879C17.8683 8.81668 18.0726 8.57458 18.077 8.293Z"
      stroke={ColorsEnum.PRIMARY}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
