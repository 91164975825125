import React from "react";
import { SxProps, Theme, Typography } from "@mui/material";
import { MessageStatusEnum } from "enums/status.enum";
import { spreadSx } from "utils/spreadSx";
import { ColorsEnum } from "enums/colors.enum";

interface ConfigInterface {
  sx: SxProps<Theme>;
}

const config: Partial<Record<MessageStatusEnum | string, ConfigInterface>> = {
  [MessageStatusEnum.PENDING]: {
    sx: {
      background: ColorsEnum.WARNING_FADED,
      color: "common.black",
    },
  },
  [MessageStatusEnum.POLICE_NOTIFIED]: {
    sx: {
      background: `${ColorsEnum.PRIMARY}29`,
      color: ColorsEnum.PRIMARY,
    },
  },
};

interface Props {
  message: MessageStatusEnum | string;
}

export const Status = ({ message }: Props) => (
  <Typography
    sx={spreadSx(
      {
        width: "min-content",
        py: 0.5,
        px: 1.25,
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "14px",
        borderRadius: 2,
        textTransform: "capitalize",
      },
      config[message]?.sx || {
        background: "#32936F29",
        color: ColorsEnum.SUCCESS,
      }
    )}
  >
    {message}
  </Typography>
);
