import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: { color: "#53545C" },
  actionButton: {
    fontSize: 12,
    textTransform: "unset",
  },
  menu: {
    mt: 0.5,
  },
  menuItem: {
    fontSize: 14,
  },
};

export default styles;
