export const predefinedMessages = {
  discount: {
    label: "Discount travel",
    title: "Discount travel",
    message: "Tickets will be discounted by 20% during July",
    messageType: "GENERAL",
  },
  suspended: {
    label: "Suspended",
    title: "Suspended",
    message:
      "Due to a signalling failure between Hannover and Bremen station all trains are suspended and will not run as usual.",
    messageType: "SPECIFIC",
  },
  covid: {
    label: "COVID Alert",
    title: "COVID Alert",
    message: `Thank you for wearing face covering if you can, as a courtesy to others.

The health and wellbeing of our customers and staff is always our top priority.
We know passengers may have concerns and rail companies are working closely with government to take the steps needed to keep our vital services moving while keeping
customers safe. `,
    messageType: "STATIC",
  },
};
