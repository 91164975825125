import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    "& .MuiDrawer-paper": {
      minWidth: 150,
      p: 2,
      pt: 3,
      background: ColorsEnum.NAV,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  version: {
    width: "100%",
    textAlign: "right",
    color: "white",
    opacity: 0.3,
    fontSize: 14,
  },
};

export default styles;
