import React, { ReactNode, useEffect, useState } from "react";
import { User, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, db } from "firestore";
import { doc, getDoc } from "firebase/firestore";
import { ErrorEnum } from "enums/error.enum";

interface AuthInterface {
  uid: string | null;
  email: string | null;
  isChecking: boolean;
  isAuthorized: boolean;
  setEmail: (token: string) => void;
  setUid: (uid: string) => void;
  signIn: (username: string, password: string) => void;
  signOut: () => void;
}

const defaultValues = {
  uid: "",
  email: "",
  isChecking: true,
  isAuthorized: false,
  setEmail: () => {},
  setUid: () => {},
  signIn: async () => {},
  signOut: () => {},
};

export const AuthContext = React.createContext<AuthInterface>(defaultValues);

export const useAuthContext = () => React.useContext(AuthContext);

interface Props {
  children: ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
  const [email, setEmail] = useState<string | null>(defaultValues.email);
  const [uid, setUid] = useState<string | null>(defaultValues.uid);
  const [isChecking, setIsChecking] = useState<boolean>(defaultValues.isChecking);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(defaultValues.isAuthorized);
  // const navigate = useNavigate();

  const shouldBeAuthorized = async (user: User) => {
    const docRef = doc(db, "user", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().role === "ADMINISTRATOR") {
        setUid(user.uid);
        setEmail(user.email);
        setIsAuthorized(true);
        return true;
      }
    }

    const error: any = new Error(ErrorEnum.NO_ACCESS);
    error.code = ErrorEnum.NO_ACCESS;
    throw error;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        setIsChecking(false);
        setIsAuthorized(false);
        return;
      }
      setEmail(user.email);
      setUid(user.uid);
      try {
        await shouldBeAuthorized(user);
      } finally {
        setIsChecking(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        email,
        setEmail,
        uid,
        setUid,
        isChecking,
        isAuthorized,
        signIn: async (username, password) => {
          setIsChecking(true);
          const { user } = await signInWithEmailAndPassword(auth, username, password);
          return await shouldBeAuthorized(user);
        },
        signOut: async () => {
          await signOut(auth);
          setEmail(null);
          setUid(null);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
