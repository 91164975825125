import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
  },
  panel: {
    pt: 6,
    px: 7,
    pb: 20,
    flex: 1,
    position: "relative",
    display: {
      xs: "none",
      md: "flex",
    },
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#050A24",
    overflow: "hidden",
    "&::before, &::after": {
      content: "''",
      position: "absolute",
      width: "25vw",
      height: "25vw",
      background: "#2D55FB",
      filter: "blur(300px)",
    },
    "&::before": {
      top: "-25%",
      right: "-50%",
    },
    "&::after": {
      bottom: "-50%",
      left: "0",
    },
  },
  logo: {
    width: {
      xs: "90%",
      md: 290,
    },
  },
  text: {
    color: "white",
    fontStyle: "italic",
    fontWeight: 300,
    fontSize: "3vw",
    background: "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.44) 100%)",
    backgroundClip: "text",
    textFillColor: "transparent",
    lineHeight: "120%",
  },
  version: {
    position: "absolute",
    color: "white",
    opacity: 0.4,
    bottom: 16,
    left: 16,
  },
  formContainer: {
    px: 5,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    width: "100%",
    maxWidth: 500,
  },
  section: {
    mb: 3,
  },
  heading: {
    mb: 4,
    fontWeight: 600,
    fontSize: 32,
  },
  input: {
    width: "100%",
  },
  button: {
    mt: 3,
    py: 1.5,
    backgroundColor: "#007DFA",
    fontSize: 18,
    textTransform: "none",
  },
  error: {
    mt: 3,
    fontSize: 18,
    fontWeight: 400,
    color: "#BEBEBF",
    whiteSpace: "pre-line",
    textAlign: "center",
  },
};

export default styles;
