import React from "react";
import { Box } from "@mui/system";
import { AlarmTypesCard } from "components/organisms/Cards/AlarmTypesCard/AlarmTypesCard";
import { PassengersCard } from "components/organisms/Cards/PassengersCard/PassengersCard";
import { UsersCard } from "components/organisms/Cards/UsersCard/UsersCard";
import { ErrorsCard } from "components/organisms/Cards/ErrorsCard/ErrorsCard";
import { MessagesCard } from "components/organisms/Cards/MessagesCard/MessagesCard";
import { AlarmsCard } from "components/organisms/Cards/AlarmsCard/AlarmsCard";
import { RecentMessagesCard } from "components/organisms/Cards/RecentMessagesCard/RecentMessagesCard";
import { SummaryCard } from "components/organisms/Cards/SummaryCard/SummaryCard";

export const Home = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(3, 1fr)"
      gap={2}
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
        },
        gridTemplateRows: {
          xs: "repeat(11,166px) auto",
          lg: "repeat(3, 166px) repeat(2, 210px) repeat(3, 166px)",
          xl: "repeat(3, 166px) repeat(2, 210px)",
        },
      }}
    >
      <Box
        sx={{
          gridArea: {
            xs: "1 / 1 / 2 / 2",
            lg: "1 / 1 / 2 / 2",
            xl: "1 / 1 / 2 / 2",
          },
        }}
      >
        <PassengersCard />
      </Box>
      <Box
        sx={{
          gridArea: {
            xs: "2 / 1 / 3 / 2",
            lg: "1 / 2 / 2 / 3",
            xl: "1 / 2 / 2 / 3",
          },
        }}
      >
        <UsersCard />
      </Box>
      <Box
        sx={{
          gridArea: {
            xs: "9 / 1 / 10 / 2",
            lg: "6 / 1 / 7 / 2",
            xl: "1 / 3 / 2 / 4",
          },
        }}
      >
        <MessagesCard />
      </Box>
      <Box
        sx={{
          gridArea: {
            xs: "3 / 1 / 5 / 2",
            lg: "2 / 1 / 4 / 2",
            xl: "2 / 1 / 4 / 2",
          },
        }}
      >
        <AlarmTypesCard />
      </Box>
      <Box
        sx={{
          gridArea: {
            xs: "5 / 1 / 6 / 2",
            lg: "2 / 2 / 3 / 3",
            xl: "2 / 2 / 3 / 3",
          },
        }}
      >
        <AlarmsCard />
      </Box>
      <Box
        sx={{
          gridArea: {
            xs: "6 / 1 / 7 / 2",
            lg: "3 / 2 / 4 / 3",
            xl: "3 / 2 / 4 / 3",
          },
        }}
      >
        <ErrorsCard />
      </Box>
      <Box
        sx={{
          gridArea: {
            xs: "7 / 1 / 9 / 2",
            lg: "4 / 1 / 6 / 3",
            xl: "4 / 1 / 6 / 3",
          },
        }}
      >
        <SummaryCard />
      </Box>
      <Box
        sx={{
          gridArea: {
            xs: "10 / 1 / 13 / 2",
            lg: "6 / 2 / 9 / 3",
            xl: "2 / 3 / 6 / 4",
          },
        }}
      >
        <RecentMessagesCard />
      </Box>
    </Box>
  );
};
