import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Card } from "components/atoms/Card/Card";
import { PeriodSelect } from "components/molecules/PeriodSelect/PeriodSelect";
import { IconBadge } from "components/atoms/IconBadge/IconBadge";
import { CardRecord } from "components/molecules/CardRecord/CardRecord";
import { FolderIcon } from "assets/icons/FolderIcon";
import { ColorsEnum } from "enums/colors.enum";
import { PeriodEnum } from "enums/period.enum";
import { createCollection } from "utils/createCollection";
import { MessagesInterface } from "interfaces/firestore/MessagesInterface";
import { Unsubscribe, onSnapshot, query, where } from "firebase/firestore";
import { getPeriodRange } from "utils/getPeriodRange";

interface LoadingInterface {
  all: boolean;
  active: boolean;
}

export const MessagesCard = () => {
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum.WEEK);
  const [allCount, setAllCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const tableDataRef = createCollection<MessagesInterface>("messages");
  const [loading, setLoading] = useState<LoadingInterface>({
    all: true,
    active: true,
  });

  useEffect(() => {
    setLoading({
      all: true,
      active: true,
    });
    const unsubscribers: Unsubscribe[] = [];

    const getData = async () => {
      const periodRange = getPeriodRange(period);

      const dateRangeParameters = [
        where("lastChange", ">=", periodRange.from),
        where("lastChange", "<=", periodRange.to),
      ];

      const qAll = await query(tableDataRef, ...dateRangeParameters);

      const unsubscribeAll = onSnapshot(qAll, async (snapshot) => {
        setAllCount(snapshot.size);
        setLoading((prevState) => ({ ...prevState, all: false }));
      });

      const qActive = await query(
        tableDataRef,
        ...dateRangeParameters,
        where("status", "==", "LIVE")
      );

      const unsubscribeActive = onSnapshot(qActive, async (snapshot) => {
        setActiveCount(snapshot.size || 0);
        setLoading((prevState) => ({ ...prevState, active: false }));
      });

      unsubscribers.push(unsubscribeAll);
      unsubscribers.push(unsubscribeActive);
    };

    getData();

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [period]);

  const handlePeriodChange = (value: PeriodEnum) => {
    setPeriod(value);
  };

  const isLoading = loading.all || loading.active;

  return (
    <Card
      sx={{
        backgroundColor: ColorsEnum.PRIMARY,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <IconBadge Icon={FolderIcon} stroke="common.white" backgroundColor="#FFFFFF29" />
        <PeriodSelect value={period} onChange={handlePeriodChange} color={"common.white"} />
      </Box>
      <Box display="flex">
        <CardRecord heading="All Messages" content={allCount} white isLoading={isLoading} />
        <CardRecord
          heading="Active"
          content={activeCount}
          difference="--.--"
          white
          isLoading={isLoading}
        />
      </Box>
    </Card>
  );
};
