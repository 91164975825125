import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  headingContainer: {
    mb: 2.5,
    display: "flex",
    justifyContent: "space-between",
    fontSize: 14,
  },
  clear: {
    color: ColorsEnum.LINK,
  },
  buttonsContainer: {
    mb: 2.5,
    padding: 0.25,
    display: "flex",
    borderRadius: 2,
    backgroundColor: ColorsEnum.NAV,
  },
  button: {
    py: 1,
    flex: 1,
    borderRadius: 1.75,
    fontSize: 14,
    lineHeight: "15px",
    color: "common.white",
  },
  buttonActive: {
    color: ColorsEnum.NAV,
    backgroundColor: "common.white",
  },
};
export default styles;
