import React, { useState, useEffect } from "react";
import {
  getCountFromServer,
  query,
  where,
  limit,
  QueryDocumentSnapshot,
  startAfter,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { useDidUpdate } from "utils/useDidUpdate";
import { createCollection } from "utils/createCollection";
import { DateRangeInterface } from "interfaces/DateRangeInterface";
import { FieldEnum } from "enums/field.enum";
import { MessagesInterface } from "interfaces/firestore/MessagesInterface";
import { OrderEnum } from "enums/order.enum";
import { Outlet } from "react-router-dom";

export interface FilterOptionsInterface {
  status: string | null;
  priority: string | null;
  type: string | null;
}

interface MessagesContextInterface {
  isLoading: boolean;
  isDateRandeModalOpen: boolean;
  search: string;
  fieldType: FieldEnum;
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
  data: MessagesInterface[];
  dateRange: DateRangeInterface;
  order: OrderEnum;
  setIsLoading: (isLoading: boolean) => void;
  setIsDateRandeModalOpen: (isDateRandeModalOpen: boolean) => void;
  setCurrentPage: (currentPage: number) => void;
  setTotalItems: (items: number) => void;
  setSearch: (search: string) => void;
  setFieldType: (field: FieldEnum) => void;
  setDateRange: (range: DateRangeInterface) => void;
  setLoadMoreFlag: (loadMore: boolean) => void;
  setOrder: (order: OrderEnum) => void;
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: (isOpen: boolean) => void;
  filterOptions: FilterOptionsInterface;
  setFilterOptions: (options: FilterOptionsInterface) => void;
}

const defaultValues = {
  isLoading: true,
  isDateRandeModalOpen: false,
  search: "",
  fieldType: FieldEnum.ID,
  itemsPerPage: 1,
  currentPage: 1,
  totalItems: 1,
  data: [],
  dateRange: {},
  order: OrderEnum.DESC,
  setIsLoading: () => {},
  setIsDateRandeModalOpen: () => {},
  setCurrentPage: () => {},
  setTotalItems: () => {},
  setSearch: () => {},
  setFieldType: () => {},
  setDateRange: () => {},
  setLoadMoreFlag: () => {},
  setOrder: () => {},
  isFilterModalOpen: false,
  setIsFilterModalOpen: () => {},
  filterOptions: {
    status: null,
    priority: null,
    type: null,
  },
  setFilterOptions: () => {},
};

export const MessagesContext = React.createContext<MessagesContextInterface>(defaultValues);

export const useMessagesContext = () => React.useContext(MessagesContext);

const itemsPerPage = 15;
let wasInputTouched = false;
let documentSnapshots: QueryDocumentSnapshot<MessagesInterface>[] = [];
let subscribers: (() => void)[] = [];

export const MessagesContextProvider = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDateRandeModalOpen, setIsDateRandeModalOpen] = useState(false);
  const [order, setOrder] = useState<OrderEnum>(OrderEnum.DESC);
  const [fieldType, setFieldType] = useState(FieldEnum.ID);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState<DateRangeInterface>({});
  const [totalItems, setTotalItems] = useState(3282);
  const [search, setSearch] = useState("");
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const tableDataRef = createCollection<MessagesInterface>("messages");
  const [data, setData] = useState<MessagesInterface[][]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState<FilterOptionsInterface>({
    status: null,
    priority: null,
    type: null,
  });

  const getData = async (recount = false, loadMore = false) => {
    const parameters = [];

    if (!loadMore) {
      setIsLoading(true);
      subscribers.forEach((subscriber) => subscriber());
      subscribers = [];
    }

    if (search) {
      parameters.push(where("message_id", "==", search));
      dateRange.from && dateRange.to && setDateRange({});
      (filterOptions.status || filterOptions.priority || filterOptions.type) &&
        setFilterOptions({
          status: null,
          priority: null,
          type: null,
        });
    }

    if (dateRange.from && dateRange.to) {
      parameters.push(where("lastChange", ">=", dateRange.from));
      parameters.push(where("lastChange", "<=", dateRange.to));
    }

    if (filterOptions.priority) {
      parameters.push(where("priority", "==", +filterOptions.priority));
    }

    if (filterOptions.status) {
      parameters.push(where("status", "==", filterOptions.status.toUpperCase()));
    }

    if (filterOptions.type) {
      parameters.push(where("messageType", "==", filterOptions.type.toUpperCase()));
    }

    !search && parameters.push(orderBy("lastChange", order));

    if (loadMore) {
      const lastVisible = documentSnapshots[documentSnapshots.length - 1];
      parameters.push(startAfter(lastVisible));
    }

    const q = await query(tableDataRef, ...parameters, limit(itemsPerPage));
    const unsubscribeGenerator = (index: number) =>
      onSnapshot(q, (querySnapshot) => {
        documentSnapshots = [...querySnapshot.docs];
        const filteredData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        let duplicatedData = [...data];
        if (!loadMore) {
          duplicatedData = [];
        }
        duplicatedData[index] = filteredData;
        setData(duplicatedData);
      });

    if (loadMore) {
      const unsubscribe = unsubscribeGenerator(data.length);
      subscribers.push(unsubscribe);
    } else {
      const unsubscribe = unsubscribeGenerator(0);
      subscribers.push(unsubscribe);
    }

    if (recount) {
      const qCount = await query(tableDataRef, ...parameters);
      const snapshot = await getCountFromServer(qCount);
      setTotalItems(snapshot.data().count);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData(true);
  }, []);

  useDidUpdate(() => {
    getData(true);
  }, [dateRange]);

  useEffect(() => {
    if (isLoading || !loadMoreFlag) {
      setLoadMoreFlag(false);
      return;
    }
    if (totalItems === data.flat().length) {
      setLoadMoreFlag(false);
      return;
    }
    getData(false, true);
    setLoadMoreFlag(false);
  }, [loadMoreFlag]);

  useDidUpdate(() => {
    if (search) {
      wasInputTouched = true;
    }
    if (!search && !wasInputTouched) return;
    if (!search && wasInputTouched) {
      getData(true);
      return;
    }
    getData(true);
  }, [search, fieldType]);

  useDidUpdate(() => {
    getData();
  }, [order]);

  useDidUpdate(() => {
    getData(true);
  }, [filterOptions]);

  return (
    <MessagesContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isDateRandeModalOpen,
        setIsDateRandeModalOpen,
        itemsPerPage,
        currentPage,
        setCurrentPage,
        totalItems,
        setTotalItems,
        search,
        setSearch,
        fieldType,
        setFieldType,
        data: data.flat(),
        dateRange,
        setDateRange,
        setLoadMoreFlag,
        order,
        setOrder,
        isFilterModalOpen,
        setIsFilterModalOpen,
        filterOptions,
        setFilterOptions,
      }}
    >
      <Outlet />
    </MessagesContext.Provider>
  );
};
