import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  table: {
    mt: 2.5,
  },
  head: {
    "& .MuiTableCell-root": {
      py: 1,
      borderTop: "solid 1px #E1E2E9",
      borderBottom: "solid 1px #E1E2E9",
    },
    "& .MuiTableCell-root:first-child": {
      pl: 0,
    },
    "& .MuiTableCell-root:last-child": {
      pr: 0,
    },
  },
  noResults: {
    textAlign: "center",
    fontSize: 16,
  },
};
export default styles;
