import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import styles from "./styles";
import { spreadSx } from "utils/spreadSx";

interface Props {
  heading: string;
  data: string[];
  active: string | null;
  onChange: (option: string) => void;
  onClear?: () => void;
}

export const ToggleButtons = ({ heading, data, active, onChange, onClear }: Props) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.headingContainer}>
        <Typography>{heading}</Typography>
        {onClear && (
          <ButtonBase sx={styles.clear} onClick={onClear}>
            Clear
          </ButtonBase>
        )}
      </Box>
      <Box sx={styles.buttonsContainer}>
        {data.map((label, i) => (
          <ButtonBase
            key={i}
            sx={spreadSx(styles.button, active === label && styles.buttonActive)}
            onClick={() => onChange(label)}
          >
            {label}
          </ButtonBase>
        ))}
      </Box>
    </Box>
  );
};
