import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingY: 1.5,
    paddingX: 2,
    color: "text.secondary",
    minHeight: "152px",
    height: "100%",
    border: "none",
    borderRadius: 3,
  },
};

export default styles;
