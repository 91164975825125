import { ColorsEnum } from "enums/colors.enum";
import { StyleInterface } from "interfaces/StyleInterface";

const styles: StyleInterface = {
  table: {
    mt: 2.5,
  },
  head: {
    "& .MuiTableCell-root": {
      py: 1,
      borderTop: "solid 1px #E1E2E9",
      borderBottom: "solid 1px #E1E2E9",
    },
    "& .MuiTableCell-root:first-child": {
      pl: 0,
    },
    "& .MuiTableCell-root:last-child": {
      pr: 0,
    },
  },
  heading: {
    mb: {
      xs: 2,
      sm: 0,
    },
    fontWeight: 500,
    fontSize: 16,
    color: ColorsEnum.HEADING,
  },
  textCell: {
    whiteSpace: "unset",
  },
  tripsContent: {
    width: 164,
  },
  messageContent: {
    width: 250,
  },
};
export default styles;
