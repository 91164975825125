import React from "react";
import { Drawer, Stack, Typography } from "@mui/material";
import { RoutesEnum } from "enums/routes.enum";
import { SidebarItem } from "components/molecules/SidebarItem/SidebarItem";
import styles from "./styles";
import { useLocation, useNavigate } from "react-router-dom";

export const MenuDrawer = () => {
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();

  const handleClose = () => {
    navigate(pathname, { replace: true });
  };

  return (
    <Drawer open={!!hash} sx={styles.container} anchor="right" onClose={handleClose}>
      <Stack spacing={1}>
        <SidebarItem to={RoutesEnum.HOME} replace>
          Home
        </SidebarItem>
        <SidebarItem to={RoutesEnum.USERS} replace>
          Users
        </SidebarItem>
        <SidebarItem to={RoutesEnum.MESSAGES} replace>
          Messages
        </SidebarItem>
        <SidebarItem to={RoutesEnum.SILENT_ALARM} replace>
          Silent Alarm
        </SidebarItem>
        <SidebarItem to={RoutesEnum.ADMIN} replace>
          Admin
        </SidebarItem>
      </Stack>
      <Typography sx={styles.version}>
        {process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT} v{process.env.REACT_APP_VERSION}
      </Typography>
    </Drawer>
  );
};
